import { useContext } from "react"
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { CookieConsent } from "react-cookie-consent";
import { LanguageContext } from "../_contexts/LanguageContext"
import strings from "../_strings/Cookies.json";
import { SLink } from "./SLink";

export const CookiesBanner = (): JSX.Element => {
  const { language } = useContext(LanguageContext);
  const consentButton = ({ children, onClick }): JSX.Element => 
    <Button data-test='cookie-consent-button' onClick={onClick} variant="contained" sx={{ m:2 }}>
      {children}
    </Button>

    return (

    <CookieConsent 
        // debug={process.env.NODE_ENV !== 'production'} // <-- commnented out instead. Othervice cypress stumbles here.
        style={{ alignItems:"flex-end" }}
        cookieName='360-consent'
        buttonText={ strings[language].approve }
        ButtonComponent={consentButton}
        // overlay
        >
        <Typography fontWeight="strong" mt='1vh'>{ strings[language].needApproval }</Typography>
        <Typography mt='1vh'>{ strings[language].cookiesExplained }</Typography>
        <Typography mt='1vh'>{ strings[language].continuedUse}</Typography>
        <Typography mt='1vh'>{ strings[language].more }
          <SLink href="/cookies">{ strings[language].cookies }</SLink>
        </Typography>
    </CookieConsent>
)}