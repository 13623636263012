/* eslint-disable react/jsx-props-no-spreading */

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "@mui/material";
import { useState } from "react";
import { modalStyle as style } from "../_styles/ModalStyle";
import { LoadingFormButton } from "./LoadingFormButton";
import { api } from "../_services/apiServices";
import { ValidatingInput } from "./ValidatingInput";
import { constraints } from "../_helpers/Constraints";

export function ForgottenPasswordModal(): JSX.Element {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState<{ value: string, error?: string, auto?: boolean, valid: boolean }>({ value: '', valid: false });
  const [enterKeyPressed, setEnterKeyPressed] = useState(false);

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const [forgottenPasswordError, showError] = useState(false);

  const submitOnKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Enter' && email.valid) {
      setEnterKeyPressed(true);
    }
  }

  const handleSubmit = async (): Promise<boolean> =>
    api().forgottenPassword(email.value).then(({ status }) => {
      if (['pending', 'fail', 'forbidden'].includes(status)) {
        handleClose()
      }
      return true;
    })

  return (
    <div>
      <Link onClick={handleOpen} data-test="forgotten-password" component='button' textAlign="center" width="100%">Glömt lösenord?</Link>
      <Modal
        open={open}
        data-test='forgotten-password-modal'
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography data-test='forgotten-password-title' variant="h5">
              Återställ lösenord
            </Typography>
            <Typography mt='2vh'>
              Ett mail med en länk för att återställa lösenordet skickas till din epostadress 
            </Typography>
            <Box
              sx={{ mt: 1 }}
            >
              <ValidatingInput
                dataTest='forgotten-password-email-input'
                id="email"
                label="example@example.com"
                autoComplete="email"
                type="email"
                autoFocus
                data={email}
                setData={setEmail}
                onKeyDown={(event:any) => submitOnKeyDown(event)}
                { ...constraints.email }
              />
              <LoadingFormButton
                disabled={ !email.valid }
                dataTest='forgotten-password-submit'
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleSubmit().catch(error => showError(true))}  
                // onClick={() => handleSubmit().catch(error => { console.log('asdf', error); showError(true) })}  
                triggerClick={enterKeyPressed}
                setTriggerClick={setEnterKeyPressed}
              >
                Skicka
              </LoadingFormButton>
              { forgottenPasswordError &&
                <Typography data-test='forgotten-password-error' color='red'>
                  {/* Addressen {values.email} är okänd för oss. Kontakta {process.env.REACT_APP_SUPPORT_EMAIL} om du tror att något är fel. */}
                  Detta fungerar inte som det ska. Kontakta supporten, så fixar vi detta via mail istället.
                </Typography>
              }
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
