import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, Box, Collapse, CssBaseline, Divider, Drawer, IconButton, List, 
  ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { userHasAccess } from "../_helpers/AccessRoles";
import browserHistory from "../_helpers/History";
import { useUser } from "../_hooks/useUser";
import { License, Relation } from "../_interfaces";
// import sleipner from "../_pics/2015_logga.png"
import { pathAttributeMap, pathList } from "./Routing";
import strings from "../_strings/MainMenu.json";
import { LanguageContext } from "../_contexts/LanguageContext";

interface MainMenuInterface {
    page: JSX.Element;
    head: string;
    // language?: string;
}

// const LicenseList = (
//     { licenses, selectedIndex, parts, handleMenuSelect, handleMenuSelectIndex, icon, path, dataTest }: 
//     {   licenses: License[], 
//         selectedIndex: number, 
//         parts: any, 
//         handleMenuSelect: any,
//         handleMenuSelectIndex: number,
//         icon: JSX.Element,
//         path: string,
//         dataTest: string
//     }): JSX.Element =>

//     <>
//         {licenses.map((license, index) => 
//             <ListItemButton
//                 selected={selectedIndex === index + handleMenuSelectIndex + parts.length + 0}
//                 data-test={dataTest}
//                 key={license.key}
//                 onClick={(e: React.MouseEvent<HTMLElement>) => {
//                     e.preventDefault();
//                     handleMenuSelect(e, index + handleMenuSelectIndex + parts.length + 0);
//                     browserHistory.push(path, {license});
//                 }}
//             >
//             { licenses.length === 1 && icon }
//             <ListItemText primary={`${license.type}: "${license.label}"`} />
//             </ListItemButton>
//         )}
//     </>

const LicenseList = (
  { licenses, icon, path, dataTest, preLabel }: 
  {   licenses: License[], 
      icon: JSX.Element,
      path: string,
      dataTest: string,
      preLabel?: string
  }): JSX.Element => {
    
  const [selectedIndex, setSelectedIndex] = useState(0);

  return <>
    { licenses.map((license, index) => 
        <ListItemButton
            key={license.key}
            selected={window.location.pathname === path && selectedIndex === index}
            data-test={dataTest}
            // string={license.key}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                setSelectedIndex(index)
                browserHistory.push(path, { license });
            }}
        >
        { licenses.length === 1 && icon }
        <ListItemText primary={`${preLabel === '' ? '' : `${preLabel} `}${license.label} ${license.type}`} />
        </ListItemButton>
    )}
  </>
}

LicenseList.defaultProps = {
  preLabel: ''
}

// const EditList = (
//     { licenses, selectedIndex, parts, handleMenuSelect }: 
//     {   licenses: License[], 
//         selectedIndex: number, 
//         parts: any, 
//         handleMenuSelect: any 
//     }): JSX.Element =>

//     <>
//         {licenses.map((license, index) => 
//             <ListItemButton
//                 selected={selectedIndex === index + parts.length + 1}
//                 key={license.key}
//                 onClick={(e: React.MouseEvent<HTMLElement>) => {
//                     e.preventDefault();
//                     handleMenuSelect(e, index + parts.length + 1);
//                     browserHistory.push('/edit', {license});
//                 }}
//             >
//             { licenses.length === 1 && icon }
//             <ListItemText primary={`${license.type}: "${license.label}"`} />
//             </ListItemButton>
//         )}
//     </>

ExpandableMenuListIfMoreThanOneChild.defaultProps = {
  menuOpen: false
}

// function ExpandableMenuListIfMoreThanOneChild (
//   { label, icon, childComponent, menuOpen }: 
//   { label: string, icon: JSX.Element, childComponent: JSX.Element, menuOpen?: boolean }
// ): JSX.Element {

function ExpandableMenuListIfMoreThanOneChild (
  { label, icon, childComponent, menuOpen, dataTest }: 
  { label: string, icon: JSX.Element, childComponent: JSX.Element, dataTest: string, menuOpen?: boolean }
): JSX.Element {

    const [open, toggleOpen] = useState(menuOpen);
    const { props } = childComponent;
    const expandable = props.licenses.length > 1;
    // console.log(dataTest)

    return expandable ? 
        <div className="expandable">
          <ListItemButton data-test={dataTest} onClick={ () => toggleOpen(!open) }>
              {icon}
              <ListItemText primary={label} />
              {open ? <FontAwesomeIcon icon='caret-up' /> : <FontAwesomeIcon icon='caret-down' />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  { childComponent }
              </List>
          </Collapse>
        </div>
        : childComponent
}    

// export function MainMenu({ page, head, language = "swedish" }: MainMenuInterface): JSX.Element {
export function MainMenu({ page, head }: MainMenuInterface): JSX.Element {
  
    const [menuOpen, setMenuOpen] = useState(Object(page).type.name === 'OrderPage');
    const { user, loggedOut } = useUser();
    const { parts, licenses } = user || { parts: [], licenses: [] };
    const userIsToChooseRespondents = parts.filter(part => part.relation === Relation.self)
    const [pathname, setPathname] = useState(window.location.pathname);
    const { language } = useContext(LanguageContext);
    
    useEffect(() => {
      setPathname(window.location.pathname)
    }, [window.location.pathname]);

    // console.log(window)
    // console.log('browserHistory.location')    
    // console.log(browserHistory.location)    
    // Contents of Menu:
    const drawer = (
      <div>
        <Toolbar
          sx={{
            backgroundImage: `url(https://www.solidcoaching.se/wp-content/uploads/2022/03/solidcoaching-logga.png)`,
            // backgroundImage: `url(${sleipner})`,
            backgroundRepeat: "no-repeat",
            // backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundColor: `hsl(0,0%,80%)`
          }}
        />
        {/* <Box sx={{ height: "20px" }} /> */}
  
        {!loggedOut && 
        <List>
    
          {/* Menu for clients and respondents: */}

          {/* Start a form */}
          {parts && parts.length > 0 && 
          <>
          { userIsToChooseRespondents && userIsToChooseRespondents.length > 0 &&
            userIsToChooseRespondents.map((userIsToChooseRespondent, index) =>
            <ListItemButton
                key={userIsToChooseRespondent.link}
                // string={userIsToChooseRespondent.link}
                data-test='menu-starts'
                selected={pathname === `/start`}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  browserHistory.push('/start');
                }}
                disabled={userIsToChooseRespondent.started}
              >
                <ListItemIcon>
                  {pathAttributeMap['/start'].icon("")}
                </ListItemIcon>
                {/* <ListItemText primary={ strings[language].startYourAnalysis } /> */}
                <ListItemText primary={ pathAttributeMap['/start'].label(language) } />
                {userIsToChooseRespondent.started && <ListItemIcon><FontAwesomeIcon icon="check" color="green"/></ListItemIcon>}
              </ListItemButton>
            )
          }
            {/* Respond to forms: */}
            <>
              <ListSubheader>
                <ListItemText>
                  { strings[language].answerAnalysisOn }
                </ListItemText>
              </ListSubheader>
              {/* { parts.filter(p => !(p.relation === Relation.self && p.client.handedIn)).map((part, index) =>  */}
              { parts.map((part, index) => 
                <ListItemButton
                  key={part.link}
                  data-test='menu-forms'
                  selected={ pathname === '/form' && part.link === (browserHistory.location.state as any)?.part?.link }
                  // disabled={ pathAttributeMap['/form'].disabled(user) || (part.relation === Relation.self && part.started === false) }
                  disabled={ 
                    pathAttributeMap['/form'].disabled(user) || 
                    (part.relation === Relation.self && part.started === false) ||
                    (part.relation === Relation.self && part.client.handedIn)
                  }
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault();
                    browserHistory.push('/form', {part});
                  }}
                >
                  <ListItemIcon>
                    {pathAttributeMap['/form'].icon("")}
                  </ListItemIcon>
                  <ListItemText primary={part.relation === Relation.self ? strings[language].yourOwnAnalysis : part.client.name} />
                  {part.relation === Relation.self && part.client.handedIn && <ListItemIcon><FontAwesomeIcon icon="check" color="green"/></ListItemIcon>}
                </ListItemButton>            
              )}
                {userHasAccess(user, '/analysis') && (
                  <ListItemButton
                    data-test="menu-analysis"
                    selected={pathname === "/analysis"}
                    disabled={pathAttributeMap["/analysis"].disabled(user)}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault();
                      browserHistory.push("/analysis");
                    }}
                  >
                    <ListItemIcon>
                      {pathAttributeMap["/analysis"].icon("")}
                    </ListItemIcon>
                    <ListItemText primary={pathAttributeMap["/analysis"].label(language)} />
                  </ListItemButton>
                )}

              </>
              <Divider />
            </>
          }
  
          {/* Menu for admins and coaches: */}
  
          {/* Products the user is licensed for: */}
          {/* { userHasAccess(user, '/order') && licenses && licenses.length > 0 &&
            <>
              <ListSubheader>
                <ListItemText>
                  Lägg beställning:
                </ListItemText>
              </ListSubheader>
  
              {licenses.map((license, index) => 
                <ListItemButton
                  // disabled={pathAttributeMap[path].disabled(user)}
                  selected={selectedIndex === index + parts.length + 1}
                  key={license.key}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault();
                    handleMenuSelect(e, index + parts.length + 1);
                    browserHistory.push('/order', {license});
                    // window.location.href = path;
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon="plus-circle" />
                  </ListItemIcon>
                  <ListItemText primary={`${license.type}: "${license.label}"`} />
                </ListItemButton>
              )}
              <Divider />
            </>
          } */}

        { userHasAccess(user, '/order') && licenses && licenses.length > 0 &&
            <ExpandableMenuListIfMoreThanOneChild
                label='Beställ'
                menuOpen={menuOpen}
                icon={
                  <ListItemIcon>
                      <FontAwesomeIcon icon="plus-circle" />
                  </ListItemIcon>    
                }
                dataTest='submenu-orders'
                childComponent={
                    <LicenseList
                        licenses={licenses}
                        dataTest='menu-orders'
                        // parts={parts}
                        path='/order'
                        // preLabel='Beställ'
                        icon={
                            <ListItemIcon>
                                <FontAwesomeIcon icon="plus-circle" />
                            </ListItemIcon>    
                        }
                    />
                }
            />
        }

        { userHasAccess(user, '/edit') && licenses && licenses.length > 0 &&
            <ExpandableMenuListIfMoreThanOneChild
                label='Ändra'
                icon={
                    <ListItemIcon>
                        <FontAwesomeIcon icon="sliders-h" />
                    </ListItemIcon>    
                }
                dataTest='submenu-edits'
                childComponent={
                    <LicenseList
                        licenses={licenses}
                        dataTest='menu-edits'
                        // parts={parts}
                        path='/edit'
                        // preLabel='Ändra'
                        icon={
                            <ListItemIcon>
                                <FontAwesomeIcon icon="sliders-h" />
                            </ListItemIcon>    
                        }
                    />
                }
            />
        }

        {
            pathList.filter(({ inMenu }) => inMenu === true).map(
                ({ path }, index) =>
                  userHasAccess(user, path) && (
                    <ListItemButton
                      key={path}
                      data-test={`menu-${path.replace('/', '')}`}
                      selected={pathname === path}
                      disabled={pathAttributeMap[path].disabled(user)}
                      // string={path}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.preventDefault();
                        browserHistory.push(path);
                      }}
                    >
                      <ListItemIcon>
                        {pathAttributeMap[path].icon("")}
                      </ListItemIcon>
                      <ListItemText primary={pathAttributeMap[path].label(language)} />
                    </ListItemButton>
                  )
              )
          }
        </List>
        }
        <Divider />
      </div>
    );
  
    const container = document.body;
    const drawerWidth = 240;
  
    const [mobileOpen, setMobileOpen] = useState(false);
  
    const handleDrawerToggle = (): void => {
      setMobileOpen(!mobileOpen);
    };

    const isNotExpandableMenuItem = (target: any, traversals = 0): boolean => {
      // if (target.className === 'expandable') {
      //   return false;
      // }
      if (target?.className === 'expandable') {
        return false;
      }
      if (traversals > 4) {
        return true;
      }
      // return isNotExpandableMenuItem(target.parentElement, traversals + 1);
      return isNotExpandableMenuItem(target?.parentElement, traversals + 1);
    }

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            {!loggedOut &&
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <FontAwesomeIcon icon="bars" />
              </IconButton>
            }
            <Typography variant="h6" noWrap component="div" data-test="page-title">
              {head && head}
            </Typography>
            {userHasAccess(user, "/account") && pathAttributeMap["/account"].icon(user.initials)}
          </Toolbar>
        </AppBar>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
            zIndex: 200
          }}
          onClick={( event ) => {
            if (isNotExpandableMenuItem(event.target)) {
              setMobileOpen(false)
            }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          anchor="left"
          open
          sx={{
            display: { xs: "none", md: "block" },
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": { width: drawerWidth, boxSizing: "border-box" },
            zIndex: 200
          }}
        >
          {drawer}
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          {page}
        </Box>
      </Box>
    );
  }  

  // MainMenu.defaultProps = {
  //   language: "swedish"
  // }
  