import { Box, Link, Typography } from "@mui/material";
import { SLink } from "../_components/SLink";

export const TermsPage = (): JSX.Element =>
    <Box style={{marginTop: '10vh', maxWidth:'640px'}}>
        <Box mt='20px'>
            <Typography variant='h5' align="center" mb='1vh'>
                Villkor för användande av tjänsten
            </Typography>
            <Typography>
                När du slutför din beställning kommer du via mail få en faktura som du är skyldig att betala inom 30 dagar.
                Du bekräftar då också att du har accepterat våra försäljningsvillkor.
            </Typography>
            <Typography mt="1vh">
                För att beställa analyser måste du först köpa licenser för den analys du vill beställa. För varje analys som beställs dras en
                licens från ditt konto. När licenserna är slut kan du inte längre beställa nya analyser förrän du har köpt nya licenser.
            </Typography>
            <Typography>
                Vi återköper inte licenser. Licenserna har idag inget sista
                giltighetsdatum, utan gäller tills dom är förbrukade eller tills tjänsten lägger ner.
            </Typography>
            {/* <Typography mt='10px'>
                Beställningen mynnar ut i en rapport i pdf-format som går att ladda ner från ditt konto efter att ordern är slutförd.
                Ordern anses som slutförd om något av följande två alternativ är uppfyllda:
            </Typography>
            <ol>
                <li>Satt slutdatum för ordern har nåtts</li>
                <li>Samtliga svar från alla berörda parter har kommit in</li>
            </ol>
            <Typography>
                Ordern anses slutförd även om inga svar har kommit in.
            </Typography> */}
        </Box>
        <Box mt='20px'>
            <Typography variant='h5' align="center" mb='1vh'>
                Vem äger rättigheterna?
            </Typography>
            <Typography>
                Innehållet på denna webbsida tillhör Fant IT AB och får inte spridas till tredje part.
                I de fall ett företag har tagit fram sina egna formulär och undersökningar så äger det företaget
                rättigheterna till materialet som har tagits fram. Vid frågor, kontakta Fant IT.<br/>
                Rapporter av analyser som har tagits fram via vår tjänst ägs av det företag vars
                coach beställde analysen.
                I dagsläget sparar vi inte data kring enskilda analyser längre än en månad efter att rapporten
                är klar. Därför är det viktigt att ladda ner rapporten och spara den på ett säkert ställe, inom
                en månad efter att analysen är färdig.<br/>
                Vi tar inget ansvar för hur rapporten används. För att beställa rapporter behöver man ha gedigna
                kunskaper inom coaching, och ska därför veta hur det är lämpligt att använda rapporten. Vi
                på Fant IT är inga coacher och har inte dom kunskaperna.
            </Typography>
        </Box>
        <Box mt='20px'>
        <Typography variant='h5' align="center" mb='1vh'>
                Vid fel eller avbrott i tjänsten
            </Typography>
            <Typography>
                Vi återgärdar fel, avbrott och brister i tjänsten löpande. Vi kan dock inte garantera att den alltid är tillgänglig,
                eller i brukbart skick - även om det självklart är vår målsättning. Om du skulle upptäcka något som ser konstigt
                ut eller inte fungerar som du förväntar dig - hör av dig till supporten, så undersöker vi och återkopplar så
                fort som möjligt.
            </Typography>
        </Box>
    </Box>
