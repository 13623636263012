import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "@mui/material/Link";
import { IconButton } from "@mui/material";
import { User } from "../_interfaces/UserInterface";
import { userHasRole } from "../_helpers/AccessRoles";
// import { useUser } from "../_hooks/useUser";
import strings from "../_strings/MainMenu.json";
import browserHistory from "../_helpers/History";

// const { loggedOut } = useUser();

export const pathList = [
  { path: "/", inMenu: false },
  { path: "/login", inMenu: false },
  { path: "/account", inMenu: false },
  { path: "/start", inMenu: false }, // start assigning respondents to participate
  { path: "/form", inMenu: false }, // answer form
  { path: "/analysis", inMenu: false }, // new
  { path: "/order", inMenu: false },
  { path: "/status", inMenu: true },
  { path: "/settings", inMenu: true },
  { path: "/edit", inMenu: false },
  { path: "/license", inMenu: false },
  { path: "/portal", inMenu: true },
  { path: "/terms", inMenu: true },
  { path: "/policy", inMenu: true },
  { path: "/logout", inMenu: true },
  // { path: "/logout", inMenu: !loggedOut },
  { path: "/thanks", inMenu: false },
  { path: "/tack", inMenu: false },
  { path: "/error", inMenu: false },
  { path: "/cookies", inMenu: false },
  { path: "/gdpr", inMenu: false },
  { path: "/faq", inMenu: false },
  { path: "/change/email", inMenu: false },
  { path: "/change/password", inMenu: false }
];

export const pathAttributeMap: {
  //  [key: string]: { label: string; icon: JSX.Element | ((initial:string) => JSX.Element) };
  [key: string]: {
    label: (value:any) => string;
    icon: (initial: string) => JSX.Element;
    access: string[];
    disabled: (user: User) => boolean;
  };
} = {
  //  "/login": { label: "Logga in", icon: <FontAwesomeIcon icon="sign-in-alt" /> },
  "/login": {
    label: (language) => strings[language].login,
    icon: () => <div />,
    access: ["public"],
    disabled: () => false
  },
  "/": {
    label: (language) => strings[language].login,
    icon: () => <div />,
    access: ["public"],
    disabled: () => false
  },
  "/logout": {
    label: (language) => strings[language].logout,
    icon: () => <FontAwesomeIcon icon="sign-out-alt" />,
    access: ["public"],
    disabled: () => false
  },
  "/error": {
    label: () => "Oväntat fel", // tas ingen hänsyn till denna
    icon: () => <div />,
    access: ["public"],
    disabled: () => true
  },
  "/thanks": {
    label: (language) => strings[language].thanks,
    icon: () => <div />,
    access: ["public"],
    disabled: () => true
  },
  "/tack": {
    label: (language) => strings[language].thanks,
    icon: () => <div />,
    access: ["public"],
    disabled: () => true
  },
  "/order": {
    label: (license) => `Beställ ${license.label} ${license.type === '360' ? '360-kartläggning' : '?'}`,
    icon: () => <FontAwesomeIcon icon="plus-circle" />,
    access: ["coach"],
    disabled: () => false
  },
  "/status": {
    label: () => "Pågående kartläggning",
    icon: () => <FontAwesomeIcon icon="thermometer-half" />,
    access: ["coach"],
    disabled: () => false
  },
  "/settings": {
    label: () => "Inställningar",
    icon: () => <FontAwesomeIcon icon="cogs" />,
    access: ["coach"],
    disabled: () => false
  },
  "/edit": {
    // label: () => "Ändra 360",
    label: (license) => `Redigera  ${license.label} ${license.type === '360' ? '360-kartläggning:' : '?'}`,
    icon: () => <FontAwesomeIcon icon="sliders-h" />,
    access: ["admin", "coach"],
    // access: ["admin"],
    disabled: () => false
  },
  "/license": {
    label: () => "Licenser",
    icon: () => <FontAwesomeIcon icon="funnel-dollar" />,
    access: ["coach"],
    disabled: () => false
  },
  "/start": {
    // label: (license) => `Starta ${license.name}s ${license.type}`, // "Starta Solids 360",
    // label: () => "Starta Solids 360",
    // label: (language) => strings[language].startYourAnalysis,
    label: (language) => strings[language || "swedish"].startYourAnalysis,
    icon: () => <FontAwesomeIcon icon="play" />,
    access: ["client"],
    disabled: () => false
  },
  "/form": {
    // label: () => "Besvara enkät",
    label: (language) => strings[language].answerAnalysisOn,
    icon: () => <FontAwesomeIcon icon="list-check" />,
    access: ["respondent", "client"],
    // disabled: (user: User) => false
    disabled: (user: User) => (userHasRole(user, 'respondent') || userHasRole(user, 'client')) === false
  },
  "/analysis": {
    label: (language) => strings[language].clientStatusLabel,
    // icon: () => <FontAwesomeIcon icon="thermometer-half" />,
    icon: () => <FontAwesomeIcon icon="person-circle-plus" />,
    access: ["client"],
    disabled: () => false
    // disabled: (user: User) => (userHasRole(user, 'client') || userHasRole(user, 'client')) === false
  },
    //  '/': { 'label':'Home', 'icon':<FontAwesomeIcon icon="home"/> },
  "/account": {
    label: () => "Kontoinställningar",
    icon: (initials: string) => (
      <IconButton
        data-test='account-button'
        color="inherit"
        aria-label="Account settings"
        edge="end"
        LinkComponent={Link}
        href="/account"
        onClick={event => {
          event.preventDefault();
          browserHistory.push('/account')
        }}
        sx={{
          mr: "0",
          ml: "auto",
          backgroundColor: "red",
          height: "42px",
          width: "42px",
        }}
      >
        { initials || "AC" }
      </IconButton>
    ),
    access: ["coach"],
    disabled: () => false
  },
  "/portal": {
    label: () => "Portalen",
    icon: () => <FontAwesomeIcon icon="circle-nodes" />,
    access: ["support"],
    disabled: () => false
  },
  "/utbildning": {
    label: () => "Utbildning",
    icon: () => <div/>,
    access: ["public"],
    disabled: () => false
  },
  "/terms": {
    label: () => "Våra villkor",
    icon: () => <FontAwesomeIcon icon="file-text" />,
    access: ["coach", "admin"],
    disabled: () => false
  },
  "/policy": {
    label: () => "Policy",
    // label: () => "Personuppgifter, datalagring och GDPR",
    icon: () => <FontAwesomeIcon icon="cookie" />,
    access: ["coach"],
    disabled: () => false
  },
  "/faq": {
    label: () => "Vanliga frågor",
    icon: () => <div />,
    access: ["client", "respondent", "coach"],
    disabled: () => false
  },
  "/cookies": {
    label: () => "Cookies",
    // label: () => "Personuppgifter, datalagring och GDPR",
    icon: () => <div/>, // <FontAwesomeIcon icon="cookie" />,
    access: ["public"],
    disabled: () => false
  },
  "/gdpr": {
    label: () => "GDPR",
    // label: () => "Personuppgifter, datalagring och GDPR",
    icon: () => <div/>, // <FontAwesomeIcon icon="cookie" />,
    access: ["public"],
    disabled: () => false
  },
  "/change/email": {
    label: () => "Byt epostadress",
    // label: () => "Personuppgifter, datalagring och GDPR",
    icon: () => <div/>, // <FontAwesomeIcon icon="cookie" />,
    access: ["public"],
    disabled: () => false
  },
  "/change/password": {
    label: () => "Byt lösenord",
    // label: () => "Personuppgifter, datalagring och GDPR",
    icon: () => <div/>, // <FontAwesomeIcon icon="cookie" />,
    access: ["public"],
    disabled: () => false
  }
};