import { useContext } from "react";
import { Typography } from "@mui/material";
import { errorCodes } from "../_components/ErrorMessages"
import { LanguageContext } from "../_contexts/LanguageContext";
import strings from "../_strings/ErrorPage.json";

export const ErrorPage = ({ error }): JSX.Element => {
    // console.log(error)
    const { language } = useContext(LanguageContext);

    return <div style={{marginTop: '10vh', maxWidth:'640px'}} data-test='error-message'>
        {
            // errorCodeTable[error]?.swedish || <Typography>{ strings[language].unknownError }</Typography>
            errorCodes({ key: error, language }) || <Typography>{ strings[language].unknownError }</Typography>
            // <div>
            // <div>{ errorCodeTable[error]?.swedish || <Typography>{ strings.swedish.unknownError }</Typography> }</div>
            // {/* <div>{ errorCodeTable[error]?.english || <Typography>{ strings.english.unknownError }</Typography> }</div> */}
            // </div>
        }
    </div>
}