import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmailForm } from "../_components/EmailForm";
import { License } from "../_interfaces";
import { Email } from "../_interfaces/EmailInterface";
import { api } from "../_services/apiServices";
import { useUser } from "../_hooks/useUser";
import { dateFormat } from "../_helpers/Dateformat";

const EmailPage = ({ license }: { license: License }): JSX.Element => {

  const { user } = useUser()
  const [emails, setEmails] = useState([{} as Email]);
  // const [activeEmail, setActiveEmail] = useState({ id: '', description: '', subject: '', body: '', placeholders: [] } as Email);
  const [activeEmail, setActiveEmail] = useState({ id: "", description: "", subject: "" } as Email);
  const [language, setLanguage] = useState("swedish");

  // const { getEmails } = api();
  // console.log({license})

  // fetching emails from api
  useEffect(() => {
    setActiveEmail({ id: "", description: "", subject: "" } as Email);
    api().getEmails(license)
      .then(fetchedEmails => {
        // setEmails({ ...fetchedEmails, subject: fetchEmails.subject[language], })
        // const asdf = fetchedEmails.map(email => 
        //   ({ ...email, subject: email.subject[language], body: email.body[language] })
        // )
        // fetchedEmails.map(asdf => {
        //   // console.log('sub ', JSON.parse(asdf.subject).then(sub => sub[language]))
        //   console.log('sub ', JSON.parse(asdf.subject)[language])
        //   return ''
        // })
        // setEmails(fetchedEmails.map(email => 
        //   ({ ...email, subject: JSON.parse(email.subject)[language], body: JSON.parse(email.body)[language] })
        // ))
        // setEmails(fetchedEmails.map(email => 
        //   ({ ...email, subject: email.subject[language], body: email.body[language] })
        // ))
        // fetchedEmails.forEach(e => { if (e.description === 'Klient: Startmail') console.log(e) })
        // console.log(fetchedEmails)
        setEmails(fetchedEmails)
      })
      .catch(e => {
        // alert('Kunde inte kontakta databasen, vänligen logga in och ut igen.');
      }
    );
  }, [license]);

  // useEffect(() => {
  //   if (emails.length > 1) {
  //     setEmails(emails.map(email => 
  //       ({ ...email, subject: email.subject[language], body: email.body[language] })
  //     ))
  //   }
  // }, [language]);

  // uppdating active email from list of emails
  useEffect(() => {
    if (emails && emails.length > 0 && typeof emails[0]?.id !== 'undefined' && activeEmail?.id === '') {
      // setActiveEmail({ ...emails[0], subject: emails[0].subject[language], body: emails[0].body[language] });
      // setActiveEmail(emails[0]);
      const chosenEmail = emails.filter(e => e.language === language);
      // setActiveEmail({ ...chosenEmail[0], subject: chosenEmail[0].subject[language], body: chosenEmail[0].body[language] });  
      setActiveEmail(chosenEmail[0]);  
    }
  }, [emails]);

  useEffect(() => {
    if (activeEmail.id !== "") {
      // const chosenEmail = emails.filter(e => e.id === activeEmail.id && e.language === language);
      const chosenEmail = emails.filter(e => e.description === activeEmail.description && e.language === language);
      // setActiveEmail({ ...chosenEmail[0], subject: chosenEmail[0].subject[language], body: chosenEmail[0].body[language] });  
      setActiveEmail(chosenEmail[0]);  
    }
  }, [language]);

  // const handleLanguageChange = (theLanguage: string): void => {
  //   setLanguage(theLanguage)
  //   if (activeEmail.id !== "") {
  //     const chosenEmail = emails.filter(e => e.id === activeEmail.id && e.language === theLanguage);
  //     setActiveEmail(chosenEmail[0]);  
  //   }
  // }

  const handleEmailChange = (event:any): void => {
    const { value } = event.target;
    // const chosenEmail = emails.filter(e => e.id === value && e.language === language);
    const chosenEmail = emails.filter(e => e.description === value && e.language === language);
    setActiveEmail(chosenEmail[0]);
    // setActiveEmail({ ...chosenEmail[0], subject: chosenEmail[0].subject[language], body: chosenEmail[0].body[language] });
  }

  const textColor = (email): string | undefined => {
    if (email.tag === 'parent') {
      return 'blue'
    }
    if (email.tag === 'default') {
      return 'red'
    }
    return undefined
  }
//  console.log({activeEmail, emails})

  return (
    <>
    {/* <Typography component='span' mt='10vh' mb='2vh'>Hantera utgående epostmeddelanden som skickas från portalen</Typography> */}
    {activeEmail?.id && 
      // <Box mt="16px" mb="8px" sx={{ minWidth: "240px", width: "100%" }}>
      <Box mt="16px" mb="8px">
        <Accordion sx={{ marginBottom: '2em' }}>
            {/* <AccordionSummary expandIcon={<FontAwesomeIcon icon={open ? "angle-up" : "angle-down"} />}> */}
            <AccordionSummary expandIcon={<FontAwesomeIcon icon="angle-down" />}>
                <b>Bra att känna till när du sparar egna mail nedan:</b>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    Mailen som sparas här är egentligen mallar för mail snarare än mail.
                    De kommer skickas så som det står, men <i>nyckelord</i> som står mellan dubbla hakparenteser byts ut när mailet skickas
                    till det som ordet inom parenteserna syftar på.
                    I listorna nedan har vi som exempel använt klienten Ada Adasson (kvinna)
                    och respondenten Beda Bedasson. Hovra med muspekaren över <i>nyckelorden</i> i listorna för att se vad de kommer bytas
                    ut till.<br/><br/>
                    Vissa <i>nyckelord</i> går att välja att ha med eller inte, men två av dem är obligatoriska i alla mail, och de måste finnas
                    med någonstans i mailtexten precis så som det står skrivet här:
                </Typography>
                <ul>
                    <li><Tooltip title={`<a href="https://www.solid.talentmanagement.se/login?access=hemlig_kod..">www.solid.talentmanagement.se/login?access=hemlig_kod...</a>`}><span>[[personlig länk]]</span></Tooltip> eller <Tooltip title={`<a href="https://www.solid.talentmanagement.se/login?access=hemlig_kod..">www.solid.talentmanagement.se/login?access=hemlig_kod...</a>`}><span>[[personal link]]</span></Tooltip> för mail på engelska</li>
                    <li><Tooltip title={dateFormat(new Date())}><span>[[sista svarsdatum]]</span></Tooltip> eller <Tooltip title={dateFormat(new Date(), true)}><span>[[last date to answer]]</span></Tooltip></li>
                </ul>
                <Typography>
                    För att de ska tolkas rätt senare så behöver de stå exakt så. Inga stora bokstäver. Inga extra mellanrum.<br/><br/>
                    Det finns ett antal andra <i>nyckelord</i> som man kan välja att ha med om man vill. Även dessa behöver skrivas exakt
                    så som det står. <i>Nyckelord</i> som går att använda ser du i listan nedan:
                </Typography>
                <ul>
                    <li><Tooltip title={`${user.name || ''}`}><span>[[mitt namn]]</span></Tooltip> / <Tooltip title={`${user.name || ''}`}><span>[[my name]]</span></Tooltip></li>
                    <li><Tooltip title={`${user.email || ''}`}><span>[[min epost]]</span></Tooltip> / <Tooltip title={`${user.email || ''}`}><span>[[my email]]</span></Tooltip></li>
                    <li><Tooltip title={`<a href='mailto:${user.email || ''}'>${user.email || ''}</a>`}><span>[[maillänk till mig]]</span></Tooltip> / <Tooltip title={`<a href='mailto:${user.email || ''}'>${user.email || ''}</a>`}><span>[[email link to me]]</span></Tooltip></li>
                    <li><Tooltip title={`<a href='mailto:support@talentmanagement.se'>support@talentmanagement.se</a>`}><span>[[maillänk till support]]</span></Tooltip> / <Tooltip title={`<a href='mailto:support@talentmanagement.se'>support@talentmanagement.se</a>`}><span>[[email link to support]]</span></Tooltip></li>
                    <li><Tooltip title='Ada Adasson'><span>[[klientens namn]]</span></Tooltip> / <Tooltip title='Ada Adasson'><span>[[clients name]]</span></Tooltip> - gäller både mail till klienten och till respondenterna</li>
                    <li><Tooltip title='Beda Bedasson'><span>[[respondentens namn]]</span></Tooltip> / <Tooltip title='Beda Bedasson'><span>[[respondents name]]</span></Tooltip> - gäller bara mail till respondenterna</li>
                    <li><Tooltip title='henne'><span>[[henne/honom/denna person]]</span></Tooltip> / <Tooltip title='her'><span>[[her/him/this person]]</span></Tooltip> - syftar på klienten</li>
                    <li><Tooltip title='henne'><span>[[henne/honom/klientens namn]]</span></Tooltip> / <Tooltip title='her'><span>[[her/him/clients name]]</span></Tooltip> - syftar på klienten</li>
                    <li><Tooltip title='hon'><span>[[hon/han/hen]]</span></Tooltip> / <Tooltip title='her'><span>[[her/his/clients name]]</span></Tooltip> - syftar på klienten</li>
                    <li><Tooltip title='hon'><span>[[hon/han/klientens namn]]</span></Tooltip> / <Tooltip title='she'><span>[[she/he/clients name]]</span></Tooltip> - syftar på klienten</li>
                </ul>
                <Typography>
                    När mailet tillsist sparas så kommer det antingen upp en notis som säger att det har sparats, eller att det
                    blev ett fel. Vid fel är det troligtvis felstavade <i>nyckelord</i> som gör att mailet inte sparas som det ska.
                    Titta igenom mailet, och speciellt <i>nyckelorden</i> och prova att spara igen.
                    Fungerar det fortfarande inte - hör av dig till oss på support@talentmanagement.se (om vi inte har hunnit få
                    dit tydligare felmeddelanden innan dess) så hjälper vi till.
                </Typography>
            </AccordionDetails>
        </Accordion>

      {/* <Box mt="16px" mb="8px"> */}
      {
        license.languages.length > 1 ? 
        <ButtonGroup 
          variant="contained" 
          sx={{
            // display: 'flex',
            // flexDirection: 'row-reverse',
            marginBottom: '20px'
          }}
        >
          <Button
            disabled={language === "swedish"}
            onClick={() => setLanguage("swedish")}
            // color={language === 'swedish' ? 'success': 'primary'}
            // onClick={() => handleLanguageChange("swedish")}
          >Svenska</Button>
          <Button
            disabled={language === "english"}
            // color={language === 'english' ? 'secondary': 'primary'}
            onClick={() => setLanguage("english")}
            // onClick={() => handleLanguageChange("english")}
          >Engelska</Button>
        </ButtonGroup> : <div/>
      }
        {/* <Box mb="20px" sx={{
          display: 'flex',
          flexDirection: 'row-reverse'
          }}>
          <Button variant="contained" size="small">Svenska</Button>
          <Button variant="outlined" size="small">Engelska</Button>
        </Box> */}
        <FormControl fullWidth>
          <InputLabel id="emailSelectionLabel">Meddelande</InputLabel>
          <Select
            id="emailSelect"
            // name="emailSelect"
            labelId="emailSelectionLabel"
            value={activeEmail.description || ''}
            label="Meddelande"
            onChange={handleEmailChange}
            // sx={{ color: textColor(emails.find(e => e.description === activeEmail.description)) }}
          >
            {
            // emails.filter(e => e.description === 'swedish').map((email) => 
            emails.filter((e, index) => 
              index === emails.findIndex( obj => obj.description === e.description)
            ).map((email) => 
              <MenuItem 
              // key={`${email.language}_${email.id}`}
              key={email.description}
              value={email.description}
              // sx={{ color: textColor(email) }}
              >
                {email.description}
              </MenuItem>)
            }
          </Select>
        </FormControl>
      </Box>
    }
    <EmailForm email={activeEmail} setEmails={setEmails} license={license} textColor={textColor} />
    </>
  );
}

export { EmailPage };
