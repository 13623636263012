import { useEffect, useState } from "react";
import { Box, Typography, Modal, Grid, Fab, IconButton, Divider, Link } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { modalStyle as style } from "../_styles/ModalStyle";
import "../_styles/Style.css";
import { useUser } from "../_hooks/useUser";
import { LoadingFormButton } from "./LoadingFormButton";
import { SLink } from "./SLink";
import { License, OrderInputProps } from "../_interfaces";
import { userHasValidInvoiceInfo } from "../_helpers/AccessRoles";
import { api } from "../_services/apiServices";
import { numberToCurrency } from "../_helpers/numberToCurrency";

BuyLicenseModal.defaultProps = {
  xs: undefined, sm: undefined, md: undefined, lg: undefined, xl: undefined
}

export function BuyLicenseModal({ disabled, refocus, orderArray, license, xs, sm, md, lg, xl }: {
  disabled: boolean, refocus: any, orderArray: OrderInputProps[], license: License, xs?: number, sm?: number, md?: number, lg?: number, xl?: number
}): JSX.Element {

  const { user, mutate } = useUser(true);
  const [open, setOpen] = useState(false);

  // const [viewTerms, toggelViewTerms] = useState(true);
  const [theLicense, updateTheLicense] = useState({ ...license, added: 0 });
  const [ammount, setAmmount] = useState({ sum: 0, vat: 0, total: 0 });

  const handleOpen = (): void => {
    updateTheLicense({ ...license, added: 1 });
    setOpen(true);
  }

  const handleClose = (): void => setOpen(false);

  const adjustedPrice = (): number => 
    theLicense.added >= theLicense.discountLimit && theLicense.discountPrice > 0 ? theLicense.discountPrice : theLicense.price

  // useEffect(() => {
  //   updateTheLicense({ ...license, added: 0 });
  // }, [license]);

  useEffect(() => {
      setAmmount( () => {
        const { discountLimit, discountPrice, price, added } = theLicense
        const sum = added * adjustedPrice() // (added >= discountLimit && discountPrice > 0 ? discountPrice : price)
        const vat = sum * 0.25;
        const total = sum + vat;
        return { sum, vat, total }
      })
  }, [theLicense]);

  const handleSubmit = async (): Promise<boolean> => {
   const req = [{ key: theLicense.key, pcs: theLicense.added }];
    return api().sendLicenseOrder(req)
    .then(() => mutate()
    .then(() => {
      handleClose()
      refocus()
      return true;
    })).catch(error => false)
  }

  const handleAdd = ( added: number ): void => {
    updateTheLicense( oldState => ({
      ...oldState,
      added: oldState.added + added
    }))
  }

  const handleDelete = ( added: number ): void => {
      updateTheLicense( oldState => ({
          ...oldState,
          added: oldState.added > added ? oldState.added - added : 0
      }))
  }

  const price = (): JSX.Element => {
    if (theLicense.price <= 0) {
      return <Typography fontSize="large" sx={{ textAlign: "right" }}>{`${theLicense.price} kr`}</Typography>
    }
    if (theLicense.discountLimit && theLicense.discountLimit === 1 && theLicense.discountPrice) {
      return <Typography fontSize="large" sx={{ textAlign: "right" }}>{`${theLicense.discountPrice} kr`}</Typography>
    }
    if (theLicense.discountLimit && theLicense.discountPrice) {
      return <>
          <Typography fontSize="small"> {`1 - ${theLicense.discountLimit - 1} st: ${theLicense.price} kr`} </Typography>
          <Typography fontSize="small"> {`${theLicense.discountLimit} eller fler: ${theLicense.discountPrice} kr`} </Typography>
        </>
    }
    return <Typography fontSize="large" sx={{ textAlign: "right" }}>{`${theLicense.price} kr`}</Typography>
  }

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} alignItems="center" sx={{ textAlign: "right" }}>
      {(license.pcs === 0 || license.pcs === orderArray.length) && (
        <>
          <Typography data-test="no-license-message" component="span" variant="h6" mr="1vh" sx={{ verticalAlign:"middle", display:{ xs: "none", sm: "inline-block" } }}>
            Köp licenser
          </Typography>
          <FontAwesomeIcon icon="arrow-right" size="2x" style={{ height:"48px", verticalAlign:"middle", marginRight:"1vh" }}/>
        </>
      )}
        <Fab 
          data-test="buy-license-button"
          color="secondary" 
          variant="extended" 
          size="large"
          disabled={disabled}
          onClick={handleOpen}
          sx={{ zIndex: 1 }}
        >
          {`Licenser kvar: ${license.pcs}${orderArray.length > 0 ? ` (${license.pcs - orderArray.length})` : `` }`}
        </Fab>
      <Modal
        data-test='buy-license-modal'
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >            
            <Typography variant="h5" data-test="buy-license-title">
              Köp licenser för { theLicense.label} { theLicense.type}
            </Typography>
            <Box sx={{ mt: 4 }} >
              <Grid container alignItems="center" sx={{ border: "1px solid lightGrey" }}>
                <Grid container>
                  <Grid item xs={2} sx={{ textAlign: "center" }}>Antal</Grid>
                  <Grid item xs={4}/>
                  <Grid item>Pris per licens</Grid>
                </Grid>
                <Divider sx={{ width: "100%" }}/>
                <Grid item xs={2} fontSize="large" data-test='license-pcs' sx={{ textAlign: "center" }}>
                  {theLicense.added}
                </Grid>
                <Grid item xs={4}>

                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <IconButton
                      data-test="add-license"
                      color="primary"
                      onClick={() => handleAdd(1)}
                      >
                        <FontAwesomeIcon icon="plus" />
                    </IconButton>                    
                  </Grid>
                  <Grid item>
                    <IconButton
                      data-test="remove-license"
                      color="primary"
                      onClick={() => handleDelete(1)} 
                    >
                      <FontAwesomeIcon icon="minus" />
                    </IconButton>
                  </Grid>
                </Grid>
                </Grid>
                <Grid item>
                  {price()}
                </Grid>
              </Grid>

              <Typography mt="1vh" variant="h6" sx={{ textAlign: "center" }}> {`Summa: ${numberToCurrency(ammount.sum)} kr`} </Typography>
              <Typography variant="h6" sx={{ textAlign: "center" }}> {`(${numberToCurrency(ammount.total)} kr inklusive moms)`} </Typography>

              <Typography mt="1vh" fontStyle="italic" fontSize="medium" sx={{ textAlign: "center" }}>Genom att bekräfta köpet nedan så godkänner du också <SLink href="/terms" newWindow>våra villkor</SLink></Typography>
              {/* <Typography mt="1vh" fontStyle="italic" fontSize="medium" sx={{ textAlign: "center" }}>Genom att bekräfta köpet nedan så godkänner du också <Link
                onClick={event => {
                  event.preventDefault()
                  toggelViewTerms(!viewTerms)
                }}>
                våra villkor
                </Link>
              </Typography> */}
              <LoadingFormButton
                dataTest='confirm-buy-license'
                sx={{ padding:'1vh', marginTop:'16px', marginBottom:'8px' }}
                onClick={handleSubmit}
                disabled={!userHasValidInvoiceInfo(user)}
              >
                Bekräfta köp av licenser
              </LoadingFormButton>
              {/* <TermsDialog open={viewTerms} setOpen={toggelViewTerms} /> */}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
}