import { createContext, useContext, useEffect, useMemo, useState } from "react";
import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faAngleRight,
  faThermometerHalf,
  faPlusCircle,
  faSave,
  faSignInAlt,
  faSignOutAlt,
  faCogs,
  faBars,
  faMale,
  faFemale,
  faTransgender,
  faTrashAlt,
  faPlus,
  faAngleDown,
  faAngleUp,
  faEnvelope,
  faWrench,
  faTimesCircle,
  faFunnelDollar,
  faSlidersH,
  faListCheck,
  faPlay,
  faFlagCheckered,
  faCircleNodes,
  faImage,
  faCaretDown,
  faCaretUp,
  faDownload,
  faTriangleExclamation,
  faCircleInfo,
  faCookie,
  faMinusCircle,
  faMinus,
  faFileText,
  faArrowRight,
  faCheck,
  faPersonCirclePlus
} from "@fortawesome/free-solid-svg-icons";
import { CookieConsent } from "react-cookie-consent";
import Button from "@mui/material/Button";
import { Link, Typography } from "@mui/material";
import browserHistory from "./_helpers/History";
import { PagePresenter } from "./_components/PagePresenter";
import { LanguageContext } from "./_contexts/LanguageContext";
import { CookiesBanner } from "./_components/CookiesBanner";

library.add(
  faHome,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faThermometerHalf,
  faPlusCircle,
  faMinusCircle,
  faSignInAlt,
  faSignOutAlt,
  faCogs,
  faWrench,
  faBars,
  faSave,
  faMale,
  faFemale,
  faTransgender,
  faTrashAlt,
  faPlus,
  faMinus,
  faEnvelope,
  faTimesCircle,
  faFunnelDollar,
  faSlidersH,
  faListCheck,
  faPlay,
  faFlagCheckered,
  faCircleNodes,
  faImage,
  faCaretDown,
  faCaretUp,
  faDownload,
  faTriangleExclamation,
  faCircleInfo,
  faCookie,
  faFileText,
  faArrowRight,
  faCheck,
  faPersonCirclePlus
);

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

/*
const theme = createTheme({
  palette: {
    primary: {
      light: "#0276aa",
      main: "#03a9f4",
      dark: "#35baf6",
      contrastText: "#fff",
    },
    secondary: {
      light: "#00a152",
      main: "#00e676",
      dark: "#33eb91",
      contrastText: "#000",
    },
  },
});
*/

// function Test() : JSX.Element {
//   return <Typography mt='1vh'><Link href="/cookies">/cookies</Link></Typography>
// }

// function Pages(): JSX.Element {
//   const [currentLocation, setLocation] = useState(browserHistory.location);
//   useEffect(() => {
//     console.log('loaded Pages')
//     const unlisten = browserHistory.listen(({ location }) => {
//         setLocation(location); // <-- problem
//     });
//     return unlisten;
//   }, []);

//   return <Typography mt='1vh'><Link href="/cookies">/cookies</Link></Typography>
//   // return <PagePresenter location={currentLocation} />
// }

function App(): JSX.Element {
  const [currentLocation, setLocation] = useState(browserHistory.location);
  const [language, changeLanguage] = useState("swedish");
  // const [languageX, changeLanguageX] = useState("swedish");

  // const langValue = useMemo(() => { return {language, changeLanguage} }, [language])
  const langValue = useMemo(() => ({ language, changeLanguage }), [language])
  // const langValue = useMemo(() => ({ language, changeLanguage}), [])

  useEffect(() => {
    // // console.log('loaded App')
    // // setLocation(browserHistory.location)
    // browserHistory.listen(({ location }) => {
    //   setLocation(location); // <-- problem?
    // });
    const unlisten = browserHistory.listen(({ location }) => {
        setLocation(location); // <-- problem?
    });
    return unlisten;
  }, []);

  // useEffect(() => {
  //   console.log(`set language in App is ${language}`)
  // }, [language]);

  // useEffect(() => {
  //   console.log(`function for changing language was updated`)
  // }, [changeLanguage]);

  // const consentButton = ({ children, onClick }): JSX.Element => 
  //   <Button data-test='cookie-consent-button' onClick={onClick} variant="contained" sx={{ m:2 }}>
  //     {children}
  //   </Button>
  // console.log('language: ', language)
  // return <Pages />
  return (
    // <LanguageContext.Provider value={{ language, changeLanguage }}>
    <LanguageContext.Provider value={langValue}>
    {/* <PagePresenter location={currentLocation} setLanguage={changeLanguage} /> */}
      <PagePresenter location={currentLocation} />
      {/* <Pages /> */}
      {/* <Test /> */}
      <CookiesBanner />
    </LanguageContext.Provider>
  )
  // return (
  //   <>
  //   {/* <LanguageContext.Provider value={language}>
  //     <PagePresenter location={currentLocation} setLanguage={changeLanguage} />
  //     <CookiesBanner />
  //   </LanguageContext.Provider> */}
  //   <LanguageContext.Provider value={{ language, changeLanguage }}>
  //     {/* <PagePresenter location={currentLocation} setLanguage={changeLanguage} /> */}
  //     <PagePresenter location={currentLocation} />
  //     <CookiesBanner />
  //   </LanguageContext.Provider>
  //     {/* <CookieConsent 
  //       // debug={process.env.NODE_ENV !== 'production'} // <-- commnented out instead. Othervice cypress stumbles here.
  //       style={{ alignItems:"flex-end" }}
  //       cookieName='360-consent'
  //       buttonText='Okej! Bort med meddelandet!'
  //       ButtonComponent={consentButton}
  //       overlay
  //     >
  //       <Typography fontWeight="strong" mt='1vh'>{ strings[language].needApproval }</Typography>
  //       <Typography mt='1vh'>{ strings[language].cookiesExplained }</Typography>
  //       <Typography mt='1vh'>{ strings[language].continuedUse}</Typography>
  //       <Typography mt='1vh'>{ strings[language].more }<Link href="/cookies">{ strings[language].cookies }</Link></Typography>
  //     </CookieConsent> */}
  //   </>
  // );
}

export default App;