import { ChangeEvent, useEffect, useState } from "react";
import { ThemeProvider } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  createTheme,
  Grid,
  Paper,
  TextField,
  Typography,
  CssBaseline,
  Link,
} from "@mui/material";
import { ForgottenPasswordModal } from "../_components/ForgottenPasswordModal";
import { NewAccountModal } from "../_components/NewAccountModal";
import compass from "../_pics/compass.jpg";
// import sleipner from "../_pics/2015_logga.png";
import { loginOrSignUp, getLandingPage, signoff } from "../_services/authenticationService";
import browserHistory from "../_helpers/History";
import { useUser } from "../_hooks/useUser";
import { LoadingFormButton } from "../_components/LoadingFormButton";
import { ResetPasswordModal } from "../_components/ResetPasswordModal";

const theme = createTheme();

export const LoginPage = ({ signedOut, signup, reset }: {signedOut?: boolean, signup?: string, reset?: string}): JSX.Element => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [enterKeyPressed, setEnterKeyPressed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { user, mutate, loggedOut, loading } = useUser(false);
  
  useEffect(() => {
    if (user && !loggedOut && !signedOut) {
      const { path, state } = getLandingPage(user);
      browserHistory.push(path, state);
    }
  }, [user, loggedOut]);

  const handleEmail = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const handlePassword = (event: ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  };

  const submitOnKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      setEnterKeyPressed(true);
    }
  }

  const handleSubmit = (): Promise<boolean> => 
    loginOrSignUp({ email, password, endpoint: 'login' })
    .then(() => mutate())
    .catch((error) => setErrorMessage('Sådär ser felaktiga inloggningsuppgifter ut'))

  const { location: { hostname } } = window;

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${compass})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Grid container>
            {/* <Grid item xs={0} sm={0} md={0} lg={0} /> */}
            <Grid
              item
              xs={12}
              // sm={12}
              // md={12}
              // lg={12}
              component={Link}
              href="https://www.solidcoaching.se/"
              
              onClick={() => signoff()}
              sx={{
                backgroundImage: `url(https://www.solidcoaching.se/wp-content/uploads/2022/03/solidcoaching-logga.png)`,
                // backgroundImage: `url(${sleipner})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
                height: "10vh",
                width: "100%",
                backgroundColor: `hsl(0,0%,80%)`
              }}
            />
          </Grid>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <FontAwesomeIcon icon="sign-in-alt" />
            </Avatar>
            <Typography data-test="title" component="h1" variant="h5">
              Logga in
            </Typography>
            <Box
              // component="form"
              // noValidate
              // onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                data-test="email-input"
                required
                fullWidth
                id="email"
                label="example@example.com"
                defaultValue={email}
                onChange={handleEmail}
                name="email"
                autoComplete="email"
                onKeyDown={(event:any) => submitOnKeyDown(event)}
                // autoFocus
              />
              <TextField
                margin="normal"
                data-test="password-input"
                required
                fullWidth
                id="password"
                label="Lösenord"
                defaultValue=""
                onChange={handlePassword}
                name="password"
                type="password"
                autoComplete="current-password"
                onKeyDown={(event:any) => submitOnKeyDown(event)}
                // Sådär ser ogiltiga inloggningsuppgifter ut.
              />
              <LoadingFormButton
                dataTest="login-submit"
                onClick={handleSubmit}
                triggerClick={enterKeyPressed}
                setTriggerClick={setEnterKeyPressed}
                sx={{ width: "100%", mt: 3, mb: 2 }}
                helperText={errorMessage}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography alignContent="center">Logga in</Typography>
                  </Grid>
                  { hostname !== '360.talentmanagement.se' && 
                    <Grid item xs={12}>
                      <Typography fontSize="x-small" color="lightgray" textAlign="center">
                        Powered by Fant IT
                      </Typography>
                    </Grid>
                  }
                </Grid>
              </LoadingFormButton>
              <Grid container>
                <Grid item xs={12}>
                  <ForgottenPasswordModal />
                </Grid>
                <Grid item>
                  <NewAccountModal showModal={signup} />
                </Grid>
                <Grid item>
                  <ResetPasswordModal showModal={reset} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

LoginPage.defaultProps = {
  signedOut: false,
  // signup: false
  signup: "",
  reset: ""
}