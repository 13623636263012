import useSWR, { KeyedMutator } from "swr";
import { User } from "../_interfaces";
import { fetcher } from "../_services/fetcher";
import { gqlRequest } from "../_services/gqlRequests";

export const useUser = (immutable?: boolean): 
{
    user: User, 
    loading: boolean, 
    loggedOut: boolean, 
    mutate: KeyedMutator<any> 
} => {

    const { data, mutate, error } = useSWR({ query: gqlRequest.useUser }, fetcher, 
        immutable ?
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            // refreshInterval: 10000
        } : {
            // refreshInterval: 10000
        }
    );

    const loading = !data && !error;
    const loggedOut = error && error.status === 403 || false;
    return {
        loading,
        loggedOut,
        user: data && data.data.user,
        mutate
    };
}