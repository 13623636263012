import { Box, Typography } from "@mui/material"
import strings from "../_strings/ErrorPage.json"

export const errorCodes = ({key, language}: {key: number, language: string}): JSX.Element => {
    switch(key) {
        case 900:
            return  <div>
                        <Typography variant='h5'>
                            { strings[language].noAccountFound }
                        </Typography>
                        <Box mt='10px'>
                            { strings[language].whyAccountNotFound}<br />
                            <ol>
                                <li>{ strings[language].timeHasRunOut }</li>
                                <li>{ strings[language].wrongEmailAddress }</li>
                            </ol>
                            { strings[language].otherReason }
                        </Box>
                    </div>
        case 1000:
            return  <Typography>{ strings[language].noSession }</Typography>
        case 1001:
            return  <div>
                        <Typography variant='h5'>{ strings[language].noUserRoles }</Typography>
                        <Typography mt='10px'>{ strings[language].noUserRolesExplained1 }</Typography>
                        <Typography>
                            <q>{ strings[language].whatShouldHaveHappened }</q>
                            { strings[language].and }
                            <q>{ strings[language].whatDidHappen }</q>
                            { strings[language].noUserRolesExplained2 }
                        </Typography>
                    </div>
        case 1002: 
            return  <div>
                        <Typography>{ strings[language].answersAlreadyHandedIn }</Typography>
                        <Typography variant='h5' mt='10px'>{ strings[language].thankyouForParticipating }</Typography>
                    </div>
        case 1003:
            return  <Typography>{ strings[language].siteExpired }</Typography>
        case 1004:
            return  <Typography>{ strings[language].reportNotReadyYet }</Typography>
        case 1005:
            return  <Typography>{ strings[language].failedToSaveAnswers }</Typography>
        case 1006:
            return  <Typography>{ strings[language].failedToRemoveAccount }</Typography>
        case 9000:
            return  <div>
                        <Typography variant='h5'>{ strings[language].hi }</Typography>
                        <Typography>{ strings[language].impossibleError }</Typography>
                    </div>
        default: 
            return <div>{ strings[language].unknownError }</div>
    }
}

// export const errorCodeTable: {[key: number]: { swedish: JSX.Element }} = {
//         900: {
//             swedish:
//             <div>
//                 <Typography variant='h5'>
//                     { strings.swedish.noAccountFound }
//                 </Typography>
//                 <Box mt='10px'>
//                     Två vanliga orsaker till att det blir så: <br />
//                     <ol>
//                         <li>
//                             Om du kom hit för att besvara en enkät så kan tiden för att besvara ha gått ut och kontot som var
//                             tillägnat dig ha tagits bort.
//                         </li>
//                         <li>
//                             Har du fått mail från oss som du inte väntade dig och kom hit för att se vad mailen handlade om?
//                             Då kan någon ha fyllt i din e-post address av misstag, för att sedan
//                             - innan du hann komma hit - ändra och fylla i en annan address. Då plockas din inloggning bort, och
//                             du hamnar på den här sidan som du har framför dig. Hej förresten! =)
//                         </li>
//                     </ol>
//                     Om det inte stämmer i ditt fall, så är vi tacksamma om du hör av dig så ska vi försöka ta reda på
//                     vad som har gått fel.
//                 </Box>
//             </div>
//         },
//         1000: {
//             swedish:
//             <Typography>
//                 Hittade inte dina uppgifter på servern. Kan det kan vara att din session har löpt ut?
//                 Prova att stänga fliken i webbläsaren för att logga in igen och se om det fungerar bättre då.
//             </Typography>
//         },
//         1001: {
//             swedish:
//             <div>
//                 <Typography variant='h5'>
//                     Ditt konto är inte förknippat med några användarroller
//                 </Typography>
//                 <Typography mt='10px'>
//                     Eller för att vara motsvarande tydlig - någonting har blivit konstigt.
//                     Hör gärna av dig till support så kikar vi på detta.
//                     Vi behöver veta <q>vad som borde ha hänt</q> och <q>vad som hände istället</q> för
//                     att ha något att utgå ifrån när vi felsöker.
//                 </Typography>
//             </div>
//         },
//         1002: {           
//             swedish:
//             <div>
//                 <Typography>{ strings.swedish.answersAlreadyHandedIn }</Typography>
//                 <Typography variant='h5' mt='10px'>{ strings.swedish.thankyouForParticipating }</Typography>
//             </div>
//         },
//         1003: {
//             swedish: 
//             <Typography>
//                 Den data du försöker nå har nått sitt slutdatum och är inte längre tillgänglig.
//             </Typography>
//         },
//         1004: {
//             swedish: 
//             <Typography>
//                 Analysen är inte klar ännu. Antingen väntar vi på slutdatum eller på att fler svar ska komma in
//                 innan vi släpper rapporten. Rapporten ska finnas dagen efter sista svarsdatum.
//             </Typography>
//         },
//         1005: {
//             swedish: 
//             <Typography>
//                 Kunde inte spara svaren. Något gick fel på servern i kontakten med databasen. Kontakta gärna oss på supporten
//                 så försöker vi reda ut vad som har hänt!
//             </Typography>
//         },
//         1006: {
//             swedish: 
//             <Typography>
//                 Försökte ta bort ett användarkonto (eventuellt i samband med att du bytte någons epost), men något blev konstigt
//                 för nu står vi här med ett felmeddelande. Kontakta gärna oss på support, så ska vi se vad som har hänt, eller åtminstone
//                 kika så att det inte dyker upp några följdproblem. Tack för hjälpen!
//             </Typography>
//         },
//         9000: {
//             swedish:
//             <div>
//                 <Typography variant='h5'>Hej!</Typography>
//                 <Typography>
//                     Det här felet borde inte hända, så det är intressant för oss att få reda på hur du kunde komma hit.
//                     Hör gärna av dig till supporten och berätta!
//                 </Typography>
//             </div>
//         },
//     }

// interface errors {
//     1002: JSX.Element
// } 

// // export const errorCodeTable: {[key: number]: {swedish: JSX.Element}} = {
// export const errorCodeTable: {[language: string]: errors} = {
//     swedish: {
//         1002:   <div>
//                     <Typography>
//                         Du har redan skickat in dina svar, och kan inte ändra på dom nu.
//                     </Typography>
//                     <Typography variant='h5' mt='10px'>
//                         Tack för din medverkan!
//                     </Typography>
//                 </div>
//     },
//     english: {
//         1002:   <div>
//                     <Typography>
//                         Your answers has already been handed in. You cannot change them at this point.
//                     </Typography>
//                     <Typography variant='h5' mt='10px'>
//                         Thank you for your participation!
//                     </Typography>
//                 </div>
//     }
//     // 900: {
//     //     swedish:
//     //     <div>
//     //         <Typography variant='h5'>
//     //             Vi kunde ej logga in dig för vi hittade inget konto
//     //         </Typography>
//     //         <Box mt='10px'>
//     //             Två vanliga orsaker till att det blir så: <br />
//     //             <ol>
//     //                 <li>
//     //                     Om du kom hit för att besvara en enkät så kan tiden för att besvara ha gått ut och kontot som var
//     //                     tillägnat dig ha tagits bort.
//     //                 </li>
//     //                 <li>
//     //                     Har du fått mail från oss som du inte väntade dig och kom hit för att se vad mailen handlade om?
//     //                     Då kan någon ha fyllt i din e-post address av misstag, för att sedan
//     //                     - innan du hann komma hit - ändra och fylla i en annan address. Då plockas din inloggning bort, och
//     //                     du hamnar på den här sidan som du har framför dig. Hej förresten! =)
//     //                 </li>
//     //             </ol>
//     //             Om det inte stämmer i ditt fall, så är vi tacksamma om du hör av dig så ska vi försöka ta reda på
//     //             vad som har gått fel.
//     //         </Box>
//     //     </div>
//     // },
//     // 1000: {
//     //     swedish:
//     //     <Typography>
//     //         Hittade inte dina uppgifter på servern. Kan det kan vara att din session har löpt ut?
//     //         Prova att stänga fliken i webbläsaren för att logga in igen och se om det fungerar bättre då.
//     //     </Typography>
//     // },
//     // 1001: {
//     //     swedish:
//     //     <div>
//     //         <Typography variant='h5'>
//     //             Ditt konto är inte förknippat med några användarroller
//     //         </Typography>
//     //         <Typography mt='10px'>
//     //             Eller för att vara motsvarande tydlig - någonting har blivit konstigt.
//     //             Hör gärna av dig till support så kikar vi på detta.
//     //             Vi behöver veta <q>vad som borde ha hänt</q> och <q>vad som hände istället</q> för
//     //             att ha något att utgå ifrån när vi felsöker.
//     //         </Typography>
//     //     </div>
//     // },
//     // 1002: {           
//     //     swedish:
//     //     <div>
//     //         <Typography>
//     //             Du har redan skickat in dina svar, och kan inte ändra på dom nu.
//     //         </Typography>
//     //         <Typography variant='h5' mt='10px'>
//     //             Tack för din medverkan!
//     //         </Typography>
//     //     </div>
//     // },
//     // 1003: {
//     //     swedish: 
//     //     <Typography>
//     //         Den data du försöker nå har nått sitt slutdatum och är inte längre tillgänglig.
//     //     </Typography>
//     // },
//     // 1004: {
//     //     swedish: 
//     //     <Typography>
//     //         Analysen är inte klar ännu. Antingen väntar vi på slutdatum eller på att fler svar ska komma in
//     //         innan vi släpper rapporten. Rapporten ska finnas dagen efter sista svarsdatum.
//     //     </Typography>
//     // },
//     // 1005: {
//     //     swedish: 
//     //     <Typography>
//     //         Kunde inte spara svaren. Något gick fel på servern i kontakten med databasen. Kontakta gärna oss på supporten
//     //         så försöker vi reda ut vad som har hänt!
//     //     </Typography>
//     // },
//     // 1006: {
//     //     swedish: 
//     //     <Typography>
//     //         Försökte ta bort ett användarkonto (eventuellt i samband med att du bytte någons epost), men något blev konstigt
//     //         för nu står vi här med ett felmeddelande. Kontakta gärna oss på support, så ska vi se vad som har hänt, eller åtminstone
//     //         kika så att det inte dyker upp några följdproblem. Tack för hjälpen!
//     //     </Typography>
//     // },
//     // 9000: {
//     //     swedish:
//     //     <div>
//     //         <Typography variant='h5'>Hej!</Typography>
//     //         <Typography>
//     //             Det här felet borde inte hända, så det är intressant för oss att få reda på hur du kunde komma hit.
//     //             Hör gärna av dig till supporten och berätta!
//     //         </Typography>
//     //     </div>
//     // },
// }
