import { Dispatch, EventHandler, useState } from "react"
import { AccountProps } from "../_interfaces/AccountInterface";
import { Email } from "../_interfaces/EmailInterface";
import { OrderInputProps, Respondent } from "../_interfaces/OrderInterface";
import { Question } from "../_interfaces/QuestionInterface";
import { api } from "../_services/apiServices";
import { constraints } from "./Constraints";
                    
const isLetter = (char: string): boolean => {
    if (char.length > 1) {
        throw new Error('Function isLetter only works for strings of length === 1');
    }
    return char.toLowerCase() !== char.toUpperCase();
}

const useFormControls = (
    initialValues: OrderInputProps | Email | AccountProps | Question | Respondent | Respondent[] | {email: string}
    ) : {
    handleInputValue : EventHandler<any>
    handleInputValidation : EventHandler<any>
    handleOrderFormSubmit : (v:OrderInputProps[]) => Promise<boolean>
    // handleAccountFormSubmit : (v?:User) => Promise<User>
    // handleEmailFormSubmit : (v?:Email) => Promise<Email>
    // handleChangeEmailAddressFormSubmit: (newEmail: string, oldEmail: string) => Promise<string>
    // handleChangeEmailAddressFormSubmit: (oldPassword: string, v?:any) => Promise<string>
    // handleChangeEmailAddressFormSubmit: (v?:string) => Promise<string>
    // handleChangePasswordFormSubmit: (password: string, repeatPassword: string, oldPassword: string) => Promise<string>
    // handleChangePasswordFormSubmit: (v?:any) => Promise<string>
    // handleForgottenPasswordSubmit : (v?:any) => Promise<boolean>
    // handleSignupFormSubmit: (v?:SignupProps) => Promise<boolean>
    handleQuestionFormSubmit: (v?:Question) => Promise<Question>
    // Promise<{
    //     user: User;
    //     page: {
    //         path: string;
    //         state?: any;
    //     };
    // } | undefined>
    formIsValid : (requiredProps: Array<string>, fieldValues?: typeof initialValues) => boolean
    errors : any
    values : any
    setValues: Dispatch<any>
} => {

    const [values, setValues] = useState(initialValues as any);
    const [errors, setErrors] = useState({} as any);

    // const constraints: { [key: string] : {min: number, max:number, required:boolean, prettyText:string } } = {
    //     "email":                { min:8,    max:64,     required:true,      prettyText:"Epostadressen" },
    //     "language":             { min:7,    max:16,     required:true,      prettyText:"Språk" },
    //     "newAccountEmail":      { min:8,    max:64,     required:true,      prettyText:"Epostadressen" },
    //     "name":                 { min:2,    max:40,     required:true,      prettyText:"Namnet" },
    //     "firstName":            { min:2,    max:40,     required:true,      prettyText:"Förnamnet" },
    //     "secondName":           { min:2,    max:40,     required:true,      prettyText:"Efternamnet" },
    //     "password":             { min:8,    max:64,     required:true,      prettyText:"Lösenordet" },
    //     "newAccountPassword":   { min:8,    max:64,     required:true,      prettyText:"Lösenordet" },
    //     "repeatPassword":       { min:8,    max:64,     required:true,      prettyText:"Lösenordet" },
    //     "confirmPassword":      { min:8,    max:64,     required:true,      prettyText:"Lösenordet" },
    //     "company":              { min:0,    max:64,     required:false,     prettyText:"Företagsnamnet" },
    //     "description":          { min:0,    max:256,    required:false,     prettyText:"Beskrivningen" },
    //     "subject":              { min:2,    max:256,    required:true,      prettyText:"Ämnet" },
    //     "body":                 { min:2,    max:6000,   required:true,      prettyText:"Meddelandet" },
    //     "questionBody":         { min:2,    max:512,    required:true,      prettyText:"Frågan" },
    //     "category":             { min:0,    max:32,     required:false,     prettyText:"Namnet på kategorin"},
    //     "city":                 { min:2,    max:64,     required:true,      prettyText:"Postorten"},
    //     "phone":                { min:8,    max:16,     required:true,      prettyText:"Telefonnumret"},
    //     "address":              { min:2,    max:64,     required:true,      prettyText:"Adressen"},
    //     "license":              { min:2,    max:128,    required:true,      prettyText:"Licensnyckeln"},
    //     "postal":               { min:0,    max:6,      required:true,      prettyText:"Postnumret"} // max 6 to allow one space
    // }
    
    // check if values are valid
    const validate : any = (fieldValues = values) : void => {
        // const temp : any = { ...errors };
        const temp : any = {};

        const checkIfParameterIsDefinedAndBetweenGivenLength = (name:string): void => {
            const value = fieldValues[name];
            temp[name] = "";
            if (constraints[name].required && (typeof value === 'undefined' || value === null || value === ""))
                temp[name] = `${constraints[name].prettyText} är obligatorisk`;
            if (value.length < constraints[name].min)
                temp[name] = `${constraints[name].prettyText} är för kort. Det ska innehålla mellan ${constraints[name].min} och ${constraints[name].max} tecken.`;
            if (value.length > constraints[name].max)
                temp[name] = `${constraints[name].prettyText} är för långt. Det ska innehålla mellan ${constraints[name].min} och ${constraints[name].max} tecken.`;
        }
        
        const checkIfParameterContainsWeirdChars = (name: string): void => {
            const value = fieldValues[name];
            // const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            // eslint-disable-next-line
            // const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            // if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
            if (/[`!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/.test(value)) {
                temp[name] = `${constraints[name].prettyText} innehåller tecken som inte förväntas finnas i ett namn`;
            }
        }

        if ("name" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('name');
            checkIfParameterContainsWeirdChars('name')
        }
        if ("language" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('language');
            checkIfParameterContainsWeirdChars('language')
        }
        if ("firstName" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('firstName');
            checkIfParameterContainsWeirdChars('firstName')
        }
        if ("secondName" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('secondName');
            checkIfParameterContainsWeirdChars('secondName')
        }
        if ("company" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('company');
        }
        if ("password" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('password');
        }
        // if ("newAccountPassword" in fieldValues) {
        //     checkIfParameterIsDefinedAndBetweenGivenLength('newAccountPassword');
        // }
        if ("confirmPassword" in fieldValues) {
            temp.confirmPassword = fieldValues.confirmPassword ? "" : "Ange lösenordet två gånger";
            if (fieldValues.confirmPassword) {
                temp.confirmPassword = fieldValues.confirmPassword === fieldValues.password ? "" : "Matchar inte lösenordet ovan"
            }
        }
        if ("email" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('email');
            if (fieldValues.email) {
                temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email) ? "" : "Epost-adressen är ogiltig"
            }
        }
        if ("newAccountEmail" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('newAccountEmail');
            if (fieldValues.newAccountEmail) {
                temp.newAccountEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.newAccountEmail) ? "" : "Epost-adressen är ogiltig"
            }
        }
        if ("description" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('description');
        }
        if ("subject" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('subject');
        }
        if ("body" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('body');
        }
        if ("questionBody" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('questionBody');
        }
        if ("category" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('category');
        }
        if ("address" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('address');
        }
        if ("license" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('license');
        }
        if ("postal" in fieldValues) {
            temp.postal = fieldValues.postal ? "" : "Postnummer behövs för att kunna lägga beställningar";
            if (fieldValues.postal) {
                const trimmedPostal = fieldValues.postal.replace(/\s+/g,'');
                if (trimmedPostal.length !== 5) {
                    temp.postal = "Postnummer är 5 siffror långa och skrivs som XXX XX eller XXXXX"
                }
                if (fieldValues.postal.length > 6) {
                    temp.postal = "Postnumret får inte vara längre än 6 tecken (inklusive mellanslag)"
                }
                if (/[^0-9]/.test(trimmedPostal)) {
                    temp.postal = "Får bara ange nummer och (ett) mellanslag i postnumret"
                }
            }
        }
        if ("phone" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('phone');
            if (fieldValues.phone) {
                const trimmedPhone = fieldValues.phone.replace(/\s+/g,'');
                if (/[^0-9-\t]/.test(trimmedPhone)) {
                    temp.phone = "Telefonnumret får bara innehålla siffror och -, som 070-XXXXXX"
                }
            }
        }
        if ("city" in fieldValues) {
            checkIfParameterIsDefinedAndBetweenGivenLength('city');
            checkIfParameterContainsWeirdChars('city')
        }
        // console.log(`errors are`)
        // console.log(errors)
        // console.log(`changing to`)
        // console.log({ ...temp })
        // console.log(`key: ${temp.key}, value: ${temp.value}. filedvalues:`)
        // console.log(fieldValues)
        // setErrors(temp[name] !== "" ? { ...temp } : {})
        // if (temp[name] !== "") {
        setErrors({ ...temp });
        // }
    }

    const handleInputValue: EventHandler<any> = (event): void => {        
        const { name, value } = event.target;        
        setValues({
            ...values,
            [name]: value
        });
    }

    const handleInputValidation : EventHandler<any> = (event): void => {
        const { name, value } = event.target;
        if (name === 'confirmPassword')
            validate({ password: values.password, confirmPassword: value });
        else
            validate({ [name]: value });
    }

    // check if form is valid
    const formIsValid = (requiredProps: Array<string> = [], fieldValues = values) : boolean => {
        // console.log(
        //     `not empty: ${requiredProps.every(p => fieldValues[p].trim() !== "")}`+
        //     `no errors: ${Object.values(errors).every((x) => x === "")}`+
        //     `within min: ${requiredProps.every(p => fieldValues[p].length >= constraints[p].min)}`+
        //     `within max: ${requiredProps.every(p => fieldValues[p].length <= constraints[p].max)}`
        //     )
        // console.log('errorz:')
        // Object.values(errors).every((x) => console.log(x))

        const isValid =
            requiredProps.every(p => fieldValues[p].trim() !== "") && 
            Object.values(errors).every((x) => x === "") &&
            requiredProps.every(p => fieldValues[p].length >= constraints[p].min) &&
            requiredProps.every(p => fieldValues[p].length <= constraints[p].max)

        // if (requiredProps.includes('password') && requiredProps.includes('repeatPassword')) {
        //     return isValid && fieldValues.repeatPassword === fieldValues.password;
        // }
        return isValid;
    }
    
    // const handleOrderFormSubmit = async (event : FormEvent<HTMLFormElement>, v:OrderInputProps[] = [values]) : Promise<void> => {
    // const handleOrderFormSubmit = async (v: OrderInputProps[] = [values]) : Promise<boolean> => {
    const handleOrderFormSubmit = async (v: OrderInputProps[]) : Promise<boolean> => {
            // event.preventDefault();

        // if (!formIsValid()) {
        //     alert('Kunde inte lägga beställning, något blev fel med formuläret. Vänligen prova igen.');
        //     return false;
        // }
        const { sendOrderForm } = api();
        return sendOrderForm(v)
        .then(({error, path}: { error?: string, path?: string }) => {
            if (error) {
                alert(`Kunde inte lägga beställningen. Något blev fel på servern:\n${error}`);
                console.error(error)
                return false;
            }
            return true;
            // browserHistory.push("/status", {fromOrder: true});
        }).catch(error => {
            alert(`Något blev fel på servern:\n${error}`);
            return false;
        });
    }

    // const handleAccountFormSubmit = async (event: FormEvent<HTMLFormElement>, user: User = values):
    // const handleAccountFormSubmit = async (user: User = values): Promise<User> => {
    //     if (!formIsValid()) {
    //         throw new Error('Värdena som skickades in ser inte ut som väntat.')
    //     }
    //     const { sendAccountForm } = api();
    //     return sendAccountForm(user);
    // }

    // const handleChangeEmailAddressFormSubmit = async (email: string = values): Promise<string> => {
    //     // event.preventDefault();
    //     if (!formIsValid()) {
    //         throw new Error('Epostadressen som skickades in ser inte ut som väntat.')
    //     }
    //     const { sendChangeEmailAddressForm } = api();
    //     return sendChangeEmailAddressForm(email);
    // }

    // const handleChangePasswordFormSubmit = async (oldPassword: string, { password } = values): Promise<string> => {
    // const handleChangePasswordFormSubmit = async ({ password, oldPassword } = values): Promise<string> => {
    //     if (!formIsValid()) {
    //         throw new Error('Epostadressen som skickades in ser inte ut som väntat.')
    //     }
    //     return api().sendChangePasswordForm(password, oldPassword);
    // }

    // // Forgotten password from loginPage:
    // const handleForgottenPasswordSubmit = async (userArgs: SignupProps = values): Promise<boolean> => 
    // {
    //     // event.preventDefault();
    //     throw new Error('not impl yet')
        
    // }

    // // const handleSignupFormSubmit = async (event: FormEvent<HTMLFormElement>, userArgs: SignupProps = values):
    // const handleSignupFormSubmit = async (userArgs: SignupProps = values):
    // Promise<boolean> =>
    //     // Promise<{ user: User, page: { path: string, state?: any }} | undefined> => 
    // {
    //     // event.preventDefault();

    //     if (formIsValid()) {
    //         // return signup({ email: userArgs.email, password: userArgs.password })
    //         return loginOrSignUp({ email: userArgs.newAccountEmail, password: userArgs.newAccountPassword, endpoint: 'signup' })
    //             .catch(() => false);
    //     }
    //     return false;
    // }

    // const handleEmailFormSubmit = async (event : FormEvent<HTMLFormElement>, v:Email = values) : Promise<Email> => {
    // const handleEmailFormSubmit = async (v:Email = values) : Promise<Email> => {
    //     const { sendEmailForm } = api();
    //     // event.preventDefault();
    //     if (formIsValid()) {
    //         return sendEmailForm(v)
    //         .catch(() => {
    //             alert(`Något blev fel på servern. Kunde inte spara meddelandet.`);
    //             return {} as Email;
    //         })
    //     }
    //     return {} as Email;
    // }

    const handleQuestionFormSubmit = async (v:Question = values) : Promise<Question> => {
        throw new Error('not impl yet')
    }
    
    return {
        handleInputValue,
        handleInputValidation,
        handleOrderFormSubmit,
        // handleAccountFormSubmit,
        // handleChangeEmailAddressFormSubmit,
        // handleChangePasswordFormSubmit,
        // handleSignupFormSubmit,
        // handleForgottenPasswordSubmit,
        // handleEmailFormSubmit,
        handleQuestionFormSubmit,
        formIsValid,
        errors,
        values,
        setValues
    }
}

export { useFormControls, isLetter }