import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";

export const PartnerCard = ({ body, footer, homepage, logo, name, backgroundColor }): JSX.Element =>
    <Card sx={{ maxWidth:640, marginTop:"8vh" }}>
        <CardActionArea href={homepage}>
            <CardMedia
                component="img"
                height="100"
                image={logo}
                alt={name}
                sx={{ objectFit:"scale-down", backgroundColor:{backgroundColor} }}
            />
        </CardActionArea>
        <CardContent>
            <Typography>{body}</Typography>
            <Typography>{footer}</Typography>
        </CardContent>
    </Card>
