import { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import strings from "../_strings/FaqPage.json";
import { LanguageContext } from "../_contexts/LanguageContext";

// export const FaqPage = ({ language } : { language: string }): JSX.Element =>
export const FaqPage = (): JSX.Element => {
    
    const { language } = useContext(LanguageContext);

    return <Grid sx={{ maxWidth:'640px' }}>
        <Typography mt='10vh' mb='1vh' variant='h5'>{ strings[language].whatIsA360 }</Typography>
        <Typography>
            { strings[language].a360is1 }
            <q>{ strings[language].example1 }</q>
            { strings[language].or }
            <q>{ strings[language].example2 }</q>
            { strings[language].a360is2 }
        </Typography>
        <Typography>{ strings[language].a360is3 }</Typography>
        <Typography variant='h5' mt='2vh' mb='1vh'>{ strings[language].aboutAnswers }</Typography>
        <Typography mt='1vh'>{ strings[language].aboutRelations }</Typography>
        <Typography>{ strings[language].aboutSuperiors }</Typography>
        <ul>
            <li>{ strings[language].answersFromClient }</li>
            <li>{ strings[language].answersFromSuperior }</li>
            <li>{ strings[language].answersFromColleagues }</li>
            <li>{ strings[language].answersFromSubordinates }</li>
            <li>{ strings[language].answersFromOthers }</li>
        </ul>
        <Typography>
            { strings[language].bestIfManyPeopleAreSignedUp1 }
            <i>{ strings[language].colleaguesInItalics }</i>
            { strings[language].bestIfManyPeopleAreSignedUp2 } 
        </Typography>
        <Typography variant='h5' mt='2vh' mb='1vh'>{ strings[language].howIsDataHandled }</Typography>
        <Typography mt='1vh'>{ strings[language].dataIsHandled }</Typography>
        <Typography variant='h5' mt='2vh' mb='1vh'>{ strings[language].whatDataIsStored }</Typography>
        <Typography mt='1vh'>{ strings[language].thisDataIsStored }</Typography>
        <ul>
            <li>{ strings[language].email }</li>
            <li>{ strings[language].possiblyAName }</li>
            <li>{ strings[language].relations }</li>
            <li>{ strings[language].language }</li>
        </ul>
        <Typography>{ strings[language].forClients }</Typography>
        <ul>
            <li>{ strings[language].company }</li>
            <li>{ strings[language].gender }</li>
            <li>{ strings[language].languageOfReport }</li>
        </ul>
        <Typography>{ strings[language].noThirdParties }</Typography>
    </Grid>
}