import axios from "axios";
import { userHasRole } from "../_helpers/AccessRoles";
import { Part, Relation } from "../_interfaces";
import { LoginCredentials } from "../_interfaces/AccountInterface";
import { User } from "../_interfaces/UserInterface";
import { gqlRequest } from "./gqlRequests";

const api = process.env.REACT_APP_API_URL;
// alert(process.env)
if (api === undefined) {
  throw new Error('url to api is not set at authentificationServices');
}

axios.defaults.withCredentials = true;

async function hello(): Promise<object> {
  const token = sessionStorage.getItem("token");

  const response = await axios.post(
    `${api}/graphql`,
    {
      withCredentials: true,
      query: gqlRequest.hello,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data.hello;
}

const setTokenInCookie = (token: string): void => {
  if (typeof token !== "undefined" && token !== null) {
    sessionStorage.setItem("token", JSON.stringify(token).slice(1, -1));
    // document.cookie = `token=${JSON.stringify(token).slice(1, -1)}`
  }
};
 
const signoff = async (): Promise<boolean> => {  
  sessionStorage.clear();
  return axios.post(`${api}/logout`).then(() => true);
};

export async function loginOrSignUp({ email, password, endpoint }: LoginCredentials): Promise<boolean> {
  return fetch({
    query: `{"email":"${email}", "password":"${password}"}`,
    endpoint
  });
}

export async function fetch({ query, endpoint }: { query: string, endpoint: string }): Promise<boolean> {
  const response = await axios.post(
    `${api}/${endpoint}`,
    { query },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).catch((error) => {
    console.log(`error in response at ${endpoint}`)
    console.log(error)
    throw error
  });
  const { data: { error, language } } = response;
  if (error) {
    // console.log('throwing error')
    // console.log(error)
    // throw error
    throw response.data
    // throw new Error(error)    
  }
  return true;
}

// async function signup({ email, password }: LoginCredentials): Promise<({ user: User, page: { path: string, state?: any } })> {
//   const response = await axios.post(
//     `${api}/login`,
//     {
//       query: gqlRequest.signup,
//       variables: { email, password },
//     },
//     {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     }
//   );
//   const res = response.data.data.signup;

//   if (res === null) {
//     return { user: {} as User, page: { path: "/" } };
//   }
  
//   const { user: { token, ...user } } = res;

//   setTokenInCookie(token);
//   return { user, page: getLandingPage(user) };
// }

const linkLogin2 = async (hash: string): Promise<boolean> =>
  fetch({
    query: `{"hash": "${hash}"}`,
    endpoint: 'login'
  });
  // const response = await axios.post(
  //   `${api}/login`,
  //   {
  //     withCredentials: true,
  //     query: gqlRequest.linkLogin2,
  //     variables: { hash },
  //   },
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   }
  // );
  // const { data: { data: { linkLogin }, errors } } = response;
  // if (errors) {
  //   signoff();
  //   throw errors;    
  // }
  // const { user: { token } } = linkLogin;
  // setTokenInCookie(token);
// }

// const linkLogin = async (hash: string): Promise<{ page: { path: string, state?: any }, user: User }> => {
//   const response = await axios.post(
//     `${api}/login`,
//     {
//       withCredentials: true,
//       query: gqlRequest.linkLogin,
//       variables: { hash },
//     },
//     {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     }
//   );

//   const { data: { data: { linkLogin: res }, errors } } = response;

//   if (errors) {
//     signoff();
//     throw errors;    
//   }

//   const { user: { token, ...user } } = res;

//   setTokenInCookie(token);
//   return { user, page: getLandingPage(user) };
// }


// async function loginAsync2( email: string, password: string): Promise<void> {
//   const response = await axios.post(
//     `${api}/login`,
//     {
//       // withCredentials: true,
//       // query: gqlRequest.loginAsync2,
//       // query: gqlRequest.loginAsync3,
//       query: `{"email":"${email}", "password":"${password}"}`,
//         // variables: { email, password },
//     },
//     {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     }
//   ).catch((error) => {
//     console.log('error in response')
//     console.log(error)
//     throw error
//   });
//   // console.log('response')
//   // console.log(response)

//   // const { data: { errors, ...data } } = response;
//   // if (errors) {
//   //   signoff();
//   //   throw errors;    
//   // }

//   // const { data: { login: { user: { token } } } } = data;

//   // setTokenInCookie(token);  
// }

// async function loginAsync(
//   email: string,
//   password: string
// ): Promise<({ page: { path: string, state?: any }, user?: User })> {

//   const response = await axios.post(
//     `${api}/login`,
//     {
//       withCredentials: true,
//       query: gqlRequest.loginAsync,
//       variables: { email, password },
//     },
//     {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     }
//   );

//   const res = response.data.data.login;
//   if (res === null) {
//     signoff();
//     return { page: { path: "/login" } };
//   }

//   const { user: { token, ...user } } = res;

//   setTokenInCookie(token);

//   return { user, page: getLandingPage(user) };
// }

const getLandingPage = (user: User, hash?: string): { path: string, state?: any } => {
  if (userHasRole(user, 'client') && user.parts.length > 0) {
    if (hash) {
      const parts = user.parts.filter(part => part.link === hash);
      if (parts.length === 1) {
        if (parts[0].relation === Relation.self && !parts[0].started) {
          return { path: '/start' };
        }
        if (parts[0].relation === Relation.self && parts[0].client.handedIn) {
          return { path: '/analysis' };
        }
        return { path: '/form', state: { part: parts[0] } }
      }
    }
    if (user.parts.some(part => part.relation === Relation.self && !part.started)) {
      return { path: '/start' };
    }
    return ({path: '/form', state: { part: user.parts[0] } });
  }

  if (userHasRole(user, 'respondent') && user.parts.length > 0) {
    if (hash) {
      const parts = user.parts.filter(part => part.link === hash);
      if (parts.length === 1) {
        return { path: '/form', state: { part: parts[0] } }
      }
      return { path: '/form', state: { part: user.parts[0] } };
    }  
  }
  
  if (userHasRole(user, 'coach')) {
    if (
      user.name && user.name !== '' &&
      user.email && user.email !== '' &&
      user.address && user.address !== '' &&
      user.postal && user.postal !== '' &&
      user.city && user.city !== '' &&
      user.company && user.company !== '' 
    ) {
      if (user.licenses.length > 0) {
        return { path: '/status', state: { fromLogin: true } }
      }
    }
    return { path: '/settings' };
  }

  if (userHasRole(user, 'admin')) {
    return { path: '/portal' };
  }
  return { path: '/login' };
}

export { 
  // loginAsync, 
  // linkLogin, 
  hello, 
  // signup, 
  signoff, 
  setTokenInCookie, 
  getLandingPage, 
  linkLogin2, 
  // loginAsync2 
};
