import { Box, SxProps, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { sv } from "date-fns/locale";
import { setDateTimeToMidDay } from "../_helpers/Dateformat";

interface IDateField {
    sx?: SxProps
    minDate?: Date
    maxDate?: Date
    value: string
    onChange: any
    dataTest: string
    helperText?: string | JSX.Element
    label?: string
    disabled?: boolean
}

const getLabel = (value: string, label?: string): string => {
    if (label === undefined) {
        return `${ value === "" ? "Inget datum satt" : "" }`
    }
    return label;
}

export const DateField = ({ sx, minDate, maxDate, value, onChange, dataTest, helperText, label: labelOrUndefined, disabled }: IDateField): JSX.Element =>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <Box sx={sx}>
            <DatePicker
                minDate={minDate}
                maxDate={maxDate}
                value={value}
                onChange={ (dateValue: Date | null, context: string | undefined) => {
                    // console.log('setting new date? dateValue, contex:')
                    // console.log({ dateValue, context })
                    if (context === undefined) {
                        if (dateValue === null) {
                            // console.log('setting date to null')
                            onChange(null)
                        }
                        else {
                            // console.log('setting date to dateValue')
                            // onChange(setDateTimeToMidDay(dateValue))
                            try {
                                const d = setDateTimeToMidDay(dateValue)
                                onChange(d)
                            } catch(error) { ; }
                        }
                    }
                    else {
                        // // Require dates with leading zero:
                        // const ymd = context.split('-');
                        // if (ymd.length === 3 && ymd[0].length === 4 && ymd[1].length === 2 && ymd[2].length === 2) {
                        //     // onChange(dateValue)
                        //     const dateInMillis = new Date(context).getMilliseconds() + 12 * 60 * 60 * 1000
                        //     onChange(new Date(dateInMillis))
                        //     // onChange(new Date(context))
                        // }
                        // onChange(setDateTimeToMidDay(context))
                        try {
                            const d = setDateTimeToMidDay(context)
                            onChange(d)
                        } catch (error) { ; }
                        // onChange(setDateTimeToMidDay(context))
                    }
                }}
                disabled={disabled}
                renderInput={({
                InputProps,
                inputProps,
                inputRef,
                ref,
                label=getLabel(value, labelOrUndefined),
                error,
                }) => (
                <TextField
                    required
                    fullWidth
                    InputProps={InputProps}
                    inputProps={{ ...inputProps, "data-test": dataTest, placeholder: "yyyy-mm-dd" }}
                    inputRef={inputRef}
                    label={label}
                    disabled={disabled}
                    error={error}
                    ref={ref}
                    helperText={helperText}
                />
                )}
            />
        </Box>
    </LocalizationProvider>

DateField.defaultProps = {
    sx: {},
    helperText: "",
    minDate: undefined,
    maxDate: undefined,
    label: undefined,
    disabled: false
}