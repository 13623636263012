export enum Gender {
  male='male',
  female='female',
  other='other'
}

export enum Relation {
  subordinate = 'subordinate',
  colleague = 'colleague',
  superior = 'superior',
  other = 'other',
  self = 'self'
}

export const nameOfRelation = (relation: string): string => {
  switch (relation) {
      case 'self':
          return 'Sig själv';
      case 'subordinate':
          return 'Underordnad';
      case 'colleague':
          return 'Kollega';
      case 'superior':
          return 'Överordnad';
      case 'other':
          return 'Övriga';
      default:
          return '';
      }
}

export interface Client {
  language: string | null;
  gender: Gender;
  relation: Relation;
  name: string;
  email: string;
  link?: string;
  handedIn: boolean;
}

export interface Respondent {
  name: string;
  email: string;
  relation: Relation;
  language: string | null;
  link?: string;
  handedIn: boolean;
}

export interface ExtendedRespondent extends Respondent {
  index: number
  approved: boolean
}

export interface RespondentInput {
  name: string;
  email: string;
  relation: Relation;
  language: string | null;
  // index: string
  // valid: boolean
}

export interface License {
  key: string,
  label: string,
  languages: string[],
  price: number,
  type: string,
  logo?: File

  discountPrice: number
  discountLimit: number
  pcs: number
  // description: string,
  // sections: Section[]
  // image: ?,
  // logo: ?,
}

export interface Part {
  link: string
  language: string
  started: boolean
  relation: Relation
  client: Client
}

export interface OrderInputProps {
  name: string              // <-- duplicate from above, used when placing an order
  email: string             // <-- duplicate from above, used when placing an order
  gender: Gender            // "male" | "female" | "other"; // <-- duplicate from above, used when placing an order
  license: string
  clientName?: string
  clientEmail?: string
  company: string | null
  endDate: Date
  reminderDate: Date | null
  respondents?: Respondent[]
}

export interface OrderProps {
  id: string,
  company: string,
  // endDate: Date,
  endDate: string,
  ended: boolean,
  started: boolean,
  // reminderDate: Date,
  reminderDate: string,
  client: Client,
  // client: Respondent,
  respondents: Respondent[],
  answers: Respondent[],
  languageOptions: string[]
}