import { AlertColor, Card, CardActions, CardContent, Divider, Grid, Step, StepLabel, Stepper, Tooltip, Typography } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { AddRespondentModal } from "../_components/AddRespondentModal";
import relationStrings from '../_strings/Relations.json';
import { LanguageContext } from "../_contexts/LanguageContext";
import { useUser } from "../_hooks/useUser";
import { SuccessNotification } from "../_components/SuccessNotification";
import { api } from "../_services/apiServices";
import { ClientStatus, Relation } from "../_interfaces";
import strings from "../_strings/ClientStatusPage.json";
import { dateFormat } from "../_helpers/Dateformat";

export const ClientStatusPage = (): JSX.Element => {
    const { user } = useUser();
    const started = user.parts.find(part => part.relation === 'self')?.started;
    const [ notification, setNotification] = useState({ open: false, message: "", severity: 'success' as AlertColor });
    const [ status, setStatus ] = useState<ClientStatus> ({ endDate: '', ended: false, groups: [], respondents: []});

    const updateStatus = (): void => {
        api().getClientStatus().then(({ errors, ...updatedStatus }) => {
            if (updatedStatus && !errors) {
                setStatus(updatedStatus);
            }
        }).catch(error => {
            console.log(error)
            // alert(`Lyckades inte hämta status: ${error}`)
        })
    }

    useEffect(() => {
        updateStatus();
    }, []);

    const { language } = useContext(LanguageContext);

    const getFreeSlots = (): number => 12 - status.groups.reduce((acc, group) => acc + group.total, 0);

    const statusText = (group: any): JSX.Element => {
        const success = '#1976d2';
        const warning = '#ff9800';
        const error = '#f44336';

        // unanswered:
        let color = warning;
        let text = group.total === 1 ? strings[language].noAnswer : strings[language].noAnswers;

        // self
        if (group.relation === Relation.self && group.answers >= 1) {
            [text, color] = [strings[language].hasAnswered, success];
        }

        // superior
        else if (group.relation === Relation.superior && group.answers >= 1) {
            color = success;
            if (group.total === 1) {
                text = strings[language].hasAnswered;
            }
            else {
                text = group.total === group.answers ? strings[language].allHaveAnswered : `${group.answers} ${strings[language].outOf} ${group.total} ${strings[language].haveAnswered}`;
            }
        }

        // the rest
        else if (group.relation !== Relation.self && group.relation !== Relation.superior) {
            if (group.total < 2) {
                color = error;
                if (group.answers >= 1) {
                    text = strings[language].hasAnswered
                }
            }
            else if (group.answers >= 1) {
                text = group.total === group.answers ? strings[language].allHaveAnswered : `${group.answers} ${strings[language].outOf} ${group.total} ${strings[language].haveAnswered}`;
                if (group.answers < 2) {
                    color = warning;
                }
                else {
                    color = success;
                }
            }
        }

        let tooltip: string | undefined;
        if (color === warning) {
            tooltip = strings[language].needMoreAnswersLong;
        }
        if (color === error) {
            tooltip = strings[language].needMoreRespondentsLong;
        }
        const msg = <Typography component="span" px="8px" py="2px" ml="10px" style={{ backgroundColor: color, fontSize: '.7em', color: 'white', borderRadius: '10px', verticalAlign: 'text-top' }}>{text}</Typography>
        return tooltip ? <Tooltip title={tooltip}>{msg}</Tooltip> : msg;
    }

    const getActiveStep = (): number => {
        if (!user.parts.find(part => part.relation === 'self')?.started) {
            return 0;
        }
        if (status.groups.find(group => group.relation === Relation.self)?.answers === 1) {
            return 2;
        }
        return 1;
    }

    const steps: string[] = [
        strings[language].step1,
        ...(status.groups.find(group => group.relation === Relation.self)?.answers === 1 ? [strings[language].step2, strings[language].step3] : [`${strings[language].step2} / ${strings[language].step3}`]),
        strings[language].step4
    ];

    return (
    <Grid container mt="3vh" component="main" direction="row" spacing={4}>
        <Grid item md={12} lg={8} xl={6}>
        <Typography gutterBottom  textAlign='center' my="4vh">
            {strings[language].analysisEndsOnThe}{dateFormat(status.endDate, language === 'english')}
        </Typography>

        <Grid container component="main" width="100%">
            { !status.ended ? (
                <Grid item style={{marginRight: 'auto', marginLeft: 'auto'}}>
                    <Stepper
                        activeStep={getActiveStep()}
                        style={{ marginBottom: '5vh' }}
                        alternativeLabel={window.innerWidth >= 460}
                        orientation={window.innerWidth < 460 ? "vertical" : "horizontal"}
                        >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Card sx={{ my: '16px', backgroundColor: 'rgb(250,250,250)' }}>
                        <CardContent>
                        { status.groups.length > 1 &&
                            <div style={{ marginTop: '16px', marginBottom: '16px' }}>
                                <Typography gutterBottom textAlign='center'>{strings[language].receivedAnswers}</Typography>
                                {status.groups.map((group) => (
                                    <Grid container key={group.relation}>
                                        <Grid item xs={2} sm={3}/>
                                        <Grid item xs={4} sm={3}>
                                            {
                                            group.total === 1 ? 
                                            relationStrings[language][group.relation] :
                                            `${relationStrings[language][`${group.relation}s`]}`
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            {statusText(group)}
                                        </Grid>
                                    </Grid>
                                    ))
                                }
                            </div>
                        }
                    { !status.ended && (status.respondents.length <= 1 || status.groups.length <= 1) &&
                        <Typography gutterBottom>
                            {strings[language].notYetStarted}
                        </Typography>
                    }
                    </CardContent>
                    </Card>
                    { status.groups.length > 1 && 
                    <Card sx={{ my: '16px', backgroundColor: 'rgb(250,250,250)' }}>
                        <CardContent>
                        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
                            <Typography gutterBottom  textAlign='center'>{strings[language].yourRespondents}</Typography>
                            <Divider sx={{ display: {xs: "block", sm: "none" }}} />
                            { status.respondents.filter(r => r.relation !== Relation.self).map((respondent) =>
                                <Fragment key={respondent.email}>
                                    <Grid  container>
                                        <Grid item xs={12} sm={4} sx={{ textAlign: {xs: "center", sm: "default"}}}>
                                            {relationStrings[language][respondent.relation]}
                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={{ textAlign: {xs: "center", sm: "default"}}}>
                                            {respondent.name}
                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={{ textAlign: {xs: "center", sm: "default"}}}>
                                            {respondent.email}
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ display: {xs: "block", sm: "none" }}} />
                                </Fragment>
                            )}
                        </div>
                        </CardContent>
                        <CardActions>
                            <AddRespondentModal disabled={!started || getFreeSlots() < 1} setNotification={setNotification} updateStatus={updateStatus} />
                        </CardActions>
                    </Card>
                    }
                </Grid>
            ) : 
            ( <Typography>{strings[language].analysisHasEnded}</Typography>)}

            {/* <AddRespondentModal disabled={!started || status.status === 'completed' || getFreeSlots() < 1} setNotification={setNotification} /> */}
            <SuccessNotification
                open={notification.open}
                handleClose={() => setNotification(oldState => ({ ...oldState, open: false }))}
                message={notification.message}
                severity={notification.severity}
            />

        </Grid>
        </Grid>
        </Grid>
)};