/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useState } from "react";
import { Box, Typography, Modal, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { modalStyle as style } from "../_styles/ModalStyle";
import "../_styles/Style.css";
import { useUser } from "../_hooks/useUser";
import { LoadingFormButton } from "./LoadingFormButton";
import { ValidatingTextData, ValidatingInput } from "./ValidatingInput";
import { constraints } from "../_helpers/Constraints";
import { api } from "../_services/apiServices";

export function ResetPasswordModal({ showModal }: { showModal?: string }): JSX.Element {

  const { mutate } = useUser(true);

  const [password, setPassword] = useState<ValidatingTextData>({ value: '', valid: false });
  const [password2, setPassword2] = useState<ValidatingTextData>({ value: '', valid: false });
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setValid(password.valid && password2.valid && password.value === password2.value)
  }, [ password.valid, password.value, password2.valid, password2.value ]);

  const [open, setOpen] = useState(showModal !== (undefined || "") || false);
  const [formError, setFormError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [enterKeyPressed, setEnterKeyPressed] = useState(false);

  const handleClose = (): void => setOpen(false);

  const submitOnKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Enter' && valid) {
      setEnterKeyPressed(true);
    }
  }

  const handleSubmit = async (): Promise<boolean> => {
    if (showModal && showModal !== "") {
      return api().confirmPasswordChange(showModal, password.value)
        .then( ({ status: responseStatus, errors }) => {
          if (responseStatus === 'ok') {
            handleClose()
            mutate()
            return true;
          }
          if (errors && errors[0].includes('Data not found')) {
            setFormError("Länken du har använt för att återställa lösenordet är inte giltig.")
          }
          return false;
        }).catch(error => {
          console.log(error)
          setFormError(`
            Någonting gick fel på servern. Prova en gång till eller
            kontakta supporten om du behöver återställa lösenordet så fixa vi det manuellt.
          `)
          return false;
        })
    }
    return Promise.resolve(false);
  }

  return (
    <div>
      <Modal
        data-test='new-account-modal'
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5" data-test="new-account-title">
              Återställ lösenord
            </Typography>
            <Box sx={{ mt: 1 }} >
              <ValidatingInput
                key='resetPassword'
                autoFocus
                dataTest='reset-password-input'
                id='resetPassword'
                label='Välj ett lösenord'
                {...constraints.password}
                data={password}
                setData={setPassword}
                type={passwordType}
                onKeyDown={(event:any) => submitOnKeyDown(event)}
              />

              <ValidatingInput
                key='confirmPassword'
                dataTest='confirm-password-input'
                id='confirmPassword'
                label='Upprepa lösenordet'
                {...constraints.password}
                data={password2}
                setData={setPassword2}
                type={passwordType}
                onKeyDown={(event:any) => submitOnKeyDown(event)}
              />

              <FormGroup>
                <FormControlLabel 
                  control={<Switch />} 
                  label="Visa lösenord"
                  checked={passwordType === "text"}
                  onChange={() => setPasswordType(passwordType === "password" ? "text" : "password")}
                />
              </FormGroup>

              <LoadingFormButton
                dataTest="change-password-submit"
                disabled={!valid}
                sx={{ width: "100%", mt: 3, mb: 2 }}
                onClick={handleSubmit}
                triggerClick={enterKeyPressed}
                setTriggerClick={setEnterKeyPressed}
              >
                Spara och logga in
              </LoadingFormButton>
              { formError !== "" &&
                <Typography data-test='new-account-error' color='red'>
                  {formError}
                </Typography>
              }
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

ResetPasswordModal.defaultProps = {
  showModal: false
}
