import { Box, CircularProgress, Typography } from "@mui/material";

export const Spinner = ({ text }: { text: string }): JSX.Element => (
    <Box sx={{ 
        position: 'absolute', 
        alignItems:'center', 
        left:'50%', 
        top:'50%',
        textAlign:'center', 
        // left: { md: `calc(50% - 240px)`, sm: '80%' },
        // left: { md: `200px`, sm: '800px' },
        // ml: { sm: `240px` },
        // backgroundColor:'rgba(0,0,0,0.1)',
        // borderRadius:'20px',
        // padding:'20px'
        }}>
        <CircularProgress sx={{display: 'inlineBlock'}}/>
        <Typography variant='h5' mr='16px' data-test='spinner' sx={{display: 'inlineBlock'}}>
            {text}
        </Typography>
    </Box>
    // <Box sx={{ display: 'flex', justifyContent:'center', height: '100vh', alignItems:'center' }}>
    //     <Typography variant='h5' mr='16px' data-test='spinner'>{text}</Typography> <CircularProgress />
    // </Box>
);