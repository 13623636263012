import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { LoadingFormButton } from "../_components/LoadingFormButton";
import { numberToCurrency } from "../_helpers/numberToCurrency";
import { useUser } from "../_hooks/useUser";
import { LicenseComponent } from "../_components/LicenseComponent";
import { SLink } from "../_components/SLink";
import { api } from "../_services/apiServices";
import { userHasValidInvoiceInfo } from "../_helpers/AccessRoles";

export const LicensePage = (): JSX.Element => {

    const { user, mutate } = useUser();
    const { licenses: userLicenses } = user;

    const [licenses, updateLicenses] = useState(userLicenses.map(license => 
        ({ ...license, added: 0 })
    ));
    const [ammount, setAmmount] = useState({ sum: 0, vat: 0, total: 0 });

    useEffect(() => {
        setAmmount( () => {
            const sum = licenses
            .map(({ added, discountLimit, discountPrice, price }) => added * (added >= discountLimit && discountPrice > 0 ? discountPrice : price))
            .reduce((tot, val) => tot + val)
            const vat = sum * 0.25;
            const total = sum + vat;
            return { sum, vat, total }
        })
    }, [licenses]);

    const { sendLicenseOrder } = api();
    const handleSubmit = async (): Promise<boolean> => {
        const req = licenses.filter(l => l.added > 0).map(l => ({ key: l.key, pcs: l.added }))
        return sendLicenseOrder(req).then(async res => 
            mutate().then( data => {
                const {data: { user: { licenses: savedLicenses } } } = data
                updateLicenses(() => savedLicenses.map(license => ({ ...license, added: 0 })))
                return true
            })
        )
    }

    const handleAdd = ({ key, added }): void => {
        updateLicenses( oldValues => {
            const newValues = oldValues.map( l => {
                if (l.key === key) {
                    return { ...l, added: l.added + added }
                }
                return l
            });
            return newValues;
        })
    }

    const handleDelete = ({ key, added }): void => {
        updateLicenses( oldValues => {
            const newValues = oldValues.map( l => {
                if (l.key === key) {
                    return { ...l, added: l.added > added ? l.added - added : 0 }
                }
                return l
            });
            return newValues;
        })
    }
        // console.log({solid: licenses.find(l => l.label === 'Solid')})
    return (
    <Box sx={{maxWidth: "1000px"}}>
        <Typography component="h1" variant="h5" mt="10vh">
            { !userHasValidInvoiceInfo(user) &&
              <p data-test='needs-settings-message'>
                Innan du kan köpa licenser behöver du först ange
                kontaktinformation under <SLink href="/settings">Inställningar</SLink>
              </p>
            }
        </Typography>

        <Typography variant='h5' mt='10vh' mb='3vh' align='center'>Köp licenser för att beställa analyser:</Typography>
        {/* <hr style={{ marginTop:'4vh', marginBottom:'2vh' }}/> */}

        <Grid 
        container 
        direction="row" 
        alignItems="center" 
        justifyContent="center" 
        borderBottom="1px solid black" 
        paddingBottom=".4em" 
        sx={{ display: { xs: "none", sm: "flex" }}}
        >
            <Grid item md={3} sm={4} sx={{ textAlign:"left" }}>Licens och pris</Grid>
            <Grid item md={4} sm={4} sx={{ textAlign:"center", display: { xs: "none", sm: "block" } }}>
            köpta + <Typography ml="5px" mr="5px" component="span" color="primary"><b>nya</b></Typography> = totalt
            </Grid>
            <Grid item md={2} sm={2} sx={{ textAlign:"center" }}>Lägg till</Grid>
            <Grid item md={2} sm={2} sx={{ textAlign:"center" }}>Ta bort</Grid>
        </Grid>
        { licenses.map(license => 
            <React.Fragment key={license.key}>
                <Divider />
                <LicenseComponent
                key={license.label}
                disabled={!userHasValidInvoiceInfo(user)}
                license={license}
                handleAdd={handleAdd}
                handleDelete={handleDelete}
                />
            </React.Fragment>
        )}

        <Divider />
        <Typography marginTop="1em" sx={{ textAlign: "center" }}> Pris: {numberToCurrency(ammount.sum)} kr ({numberToCurrency(ammount.total)} kr inklusive moms)</Typography>
        <Typography sx={{ textAlign: "center" }}> När du bekräftar köpet nedan så godkänner du också <SLink href="/terms">våra villkor.</SLink></Typography>        
        <LoadingFormButton
            sx={{ padding:'1vh', marginTop:'16px', marginBottom:'8px' }}
            onClick={handleSubmit}
            disabled={!userHasValidInvoiceInfo(user)}
        >
            Bekräfta köp av licenser
        </LoadingFormButton>
    </Box>
    );
}