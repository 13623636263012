import { useContext } from "react";
import { Box, Link, Typography } from "@mui/material";
import strings from '../_strings/GDPRPage.json';
import { LanguageContext } from "../_contexts/LanguageContext";
import { useUser } from "../_hooks/useUser";

export const GDPRPage = (): JSX.Element => {
    
    const { language } = useContext(LanguageContext);
    const { user } = useUser();

    return <div style={{marginTop: '10vh', maxWidth:'640px'}}>        
        <Box>
            <Typography variant="h5" align="center">{ strings[language].personalData }</Typography>
            <Typography variant="h6" marginTop="1em">{ strings[language].generalTitle }</Typography>
            <Typography>
                { strings[language].generalText1 } <br/><br/>
                { strings[language].generalText2 } <br/><br/>
                { strings[language].generalText3 } <br/><br/>
                { strings[language].generalText4 }
            </Typography>
            { (user === undefined || user.roles.includes('respondent')) && 
                <>
                    <Typography variant="h6" marginTop="1em">{ strings[language].respondentTitle }</Typography>
                    <Typography>
                        { strings[language].respondentText }
                    </Typography>
                </>
            }
            { (user === undefined || user.roles.includes('client')) && 
                <>
                    <Typography variant="h6" marginTop="1em">{ strings[language].clientTitle }</Typography>
                    <Typography>
                        { strings[language].clientText }
                    </Typography>
                </>
            }
            { (user === undefined || user.roles.includes('coach')) && 
                <>
                    <Typography variant="h6" marginTop="1em">{ strings[language].coachTitle }</Typography>
                    <Typography>
                        { strings[language].coachText }
                    </Typography>
                </>
            }
            {/* <Typography variant="h6" marginTop="1em">{ strings[language].clientTitle }</Typography>
            <Typography>
                { strings[language].clientText }
            </Typography>
            <Typography variant="h6" marginTop="1em">{ strings[language].coachTitle }</Typography>
            <Typography>
                { strings[language].coachText }
            </Typography> */}
            <Typography variant="h6" marginTop="1em">{ strings[language].rightsTitle }</Typography>
            <Typography>
                { strings[language].rightsText1 } <br/><br/>
                { strings[language].rightsText2 } <br/><br/>
                { strings[language].rightsText3 }
                <Link href={`https://www.${ strings[language].urlToIMY }`}>{ strings[language].urlToIMY }</Link>
                <br/><br/>
            </Typography>
            <Typography borderTop="1px solid gray" textAlign="right">
                { strings[language].lastUpdated }                
            </Typography>
         </Box>
    </div>
}