import { pathAttributeMap } from "../_components/Routing";
import { Relation, Respondent } from "../_interfaces/OrderInterface";
import { User } from "../_interfaces/UserInterface";

// const userHasRole = (user: User, role: string): boolean => user.roles.indexOf(role) > -1;

const userHasRelation = (user: Respondent, realtion: string): boolean => // user.relations.indexOf(realtion) > -1;
typeof user.relation !== 'undefined' && user.relation.indexOf(realtion) > -1;

// const userHasAccess = (user: User, path: string): boolean => {
//     // Allow access to public paths:
//     if (pathAttributeMap[path].access === "all") return true;
  
//     // Also deny access if user has no roles
//     return user && user.roles && user.roles.indexOf(pathAttributeMap[path].access) > -1;
// };

const userHasAccess = (user: User, path: string, state?: any): boolean => {
    // Allow access to public paths:
    // if (pathAttributeMap[path].access.includes("all")) return true;
    if (pathAttributeMap[path].access.includes("public")) return true;
    // user has access to /form, but not for this particular client ((any more)).
    if (state && user) {
        // console.log(user, state, userHasRoles(user, pathAttributeMap[path].access), user.parts.some(part => part.link === state.link))
        // !user.parts.some(part => part.relation === Relation.self && part.client.handedIn)

        // return userHasRoles(user, pathAttributeMap[path].access) && user.parts.some(part => part.link === state.link);
        const thePart = user.parts.find(part => part.link === state.link);
        // console.log({thePart})
        return (
            userHasRoles(user, pathAttributeMap[path].access) && 
            user.parts.some(part => part.link === state.link) && (
                thePart?.relation !== Relation.self || (
                    thePart?.relation === Relation.self && 
                    !thePart?.client.handedIn
                )
            )
        );
    }
    // console.log(user, path, state);
    // Also deny access if user has no roles
    return userHasRoles(user, pathAttributeMap[path].access);
};

const userHasRoles = (user: User, roles: string[]): boolean => roles.some(role => userHasRole(user, role))

const userHasRole = (user: User, role: string): boolean => user && user.roles && user.roles.indexOf(role) > -1;

const userHasValidInvoiceInfo = (user: User): boolean =>
    user.name    !== null && user.name    !== '' &&
    user.email   !== null && user.email   !== '' &&
    user.address !== null && user.address !== '' &&
    user.city    !== null && user.city    !== '' &&
    user.postal  !== null && user.postal  !== '' &&
    user.company !== null && user.company !== '';

export { userHasRole, userHasAccess, userHasRelation, userHasValidInvoiceInfo }