import { Box } from "@mui/material";
import { PartnerCard } from "../_components/PartnerCard";

export const EducationPage = () : JSX.Element =>
    <>
        <PartnerCard
            body="Solid AffärsCoaching har tagit fram frågeställningar till en 360-kartläggning.
            Med denna får man som coach ut en rapport som kan användas som ett stöd vid samtal
            med sin klient. Man får en överblick över sina utvecklingsområden som personer kring
            klienten upplever att hen har, och utifrån det kan man forma en väg framåt i coachingen.
            Den kan också fungera som en återkoppling efter en tids coaching för att se om klientens närmaste
            medarbetare upplever att det har blivit någon förändring inom de områden man har försökt förbättra."
            footer="För att få tillgång till Solids 360-kartläggning behöver du först gå en utbildning hos dem."
            homepage="https://www.solidcoaching.se"
            logo="https://www.solidcoaching.se/wp-content/uploads/2022/03/solidcoaching-logga.png"
            name="Solid Coaching"
            backgroundColor="hsl(0, 0%, 80%)"
        />
        <PartnerCard
            body="Fant IT är vi som driver denna sida på talentmanagement.se.
            Vi har inga egna utbildningar eller enkätundersökningar.
            Vad gör vi då på den här sidan som handlar om utbildningar?
            Reklam!
            Om du eller ditt företag är en sådan som kan
            saker, och som har nytta av en specifik form av enkätundersökning, som exempelvis en 360-kartläggning?
            Hör. Av. Dig. Nu! Så hjälper vi till att ta fram ett verktyg till er. Maila oss på info@sleipner.nu."
            footer=''
            logo="http://www.sleipnertechnology.com/pics/2015_logga.png"
            homepage="http://www.sleipnertechnology.com"
            name="Fant IT"
            backgroundColor="hsl(0, 0%, 80%)"
        />
    </>