import { AlertColor, Divider, Grid, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { LoadingFormButton } from "../_components/LoadingFormButton";
import { SuccessNotification } from "../_components/SuccessNotification";
import browserHistory from "../_helpers/History";
import { useUser } from "../_hooks/useUser";
import { User } from "../_interfaces";
import { getLandingPage } from "../_services/authenticationService";
import { ChangeEmailModal } from "../_components/ChangeEmailModal";
import { ChangePasswordModal } from "../_components/ChangePasswordModal";
import { useForm } from "../_helpers/useForm";
import { api } from "../_services/apiServices";

export const SettingsPage = (): JSX.Element => {

  const { user, mutate } = useUser();
  const [ notification, setNotification] = useState({ open: false, message: "Sparade användardata!", severity: 'success' as AlertColor });
  const nameRef = useRef<HTMLInputElement>();

  const inputFieldValues = [
    { name: 'name', label: 'För- och efternamn *', id: 'name', dataTest: 'name-input' },
    { name: 'company', label: 'Företag *', id: 'company', dataTest: 'company-input' },
    { name: 'address', label: 'Faktureringsadress *', id: 'address', dataTest: 'address-input' },
    { name: 'postal', label: 'Postnummer *', id: 'postal', dataTest: 'postal-input' },
    { name: 'city', label: 'Postort *', id: 'city', dataTest: 'city-input' },
    { name: 'phone', label: 'Telefonnummer', id: 'phone', dataTest: 'phone-input' },
  ];

  const defaults = {
    name: { value: user?.name || "", error: "" },
    company: { value: user?.company || "", error: "" },
    address: { value: user?.address || "", error: "" },
    postal: { value: user?.postal || "", error: "" },
    city: { value: user?.city || "", error: "" },
    phone: { value: user?.phone || "", error: "" }
  }

  const { values, valid, handleInputValidation, handleInputValues } = useForm(defaults)

  useEffect(() => {
    setTimeout(() => {
      const nameField = nameRef.current;
      if (nameField) {
        nameField.focus()
      }
      handleInputValidation({ target: { ...values.name }})
    }, 50)
  },[]);

  const userFromValues = (): any => ({
    name: values.name.value,
    company: values.company.value,
    address: values.address.value,
    postal: values.postal.value,
    city: values.city.value,
    phone: values.phone.value
  })

  const handleSubmit = async(): Promise<boolean> =>
    api().sendAccountForm(userFromValues() as User)
    .then((u: User) => mutate()
    .then(({ data: { user: userData }}) => {
      const { path, state } = getLandingPage(userData);
      if (path !== '/settings') {
        browserHistory.push('/order', { license: user.licenses[0] });  
      }
      return true;
    }))
    .catch(error => {
      alert(`Lyckades inte spara: ${error}`)
      return false;
    })

  return (
    <>
      <Grid mt='10vh'>
        {inputFieldValues.map(field => 
          <TextField 
            key={field.id}
            id={field.id}
            name={field.name}
            inputRef={ field.name === 'name' ? nameRef : undefined }
            inputProps={{
              "data-test": field.dataTest
            }}
            label={field.label}
            margin='normal'
            helperText={<span style={{ color:"red" }}>{values[field.name].error}</span>}
            onChange={handleInputValues} 
            onBlur={(e) => {
              handleInputValues(e);
              handleInputValidation(e);
            }}
            type='text'
            value={values[field.name].value}
            fullWidth
          />
        )}
        <LoadingFormButton
          sx={{ padding:'1vh', marginTop:'16px', marginBottom:'8px' }}
          disabled={!valid}
          onClick={handleSubmit}
        >
          Spara 
        </LoadingFormButton>
      </Grid>
      <Divider sx={{ marginY: '2vh' }} />
      <Grid>
        <ChangeEmailModal setNotification={setNotification} oldEmail={user.email} />
      </Grid>
      <Divider sx={{ marginY: '2vh' }} />
      <Grid>
        <ChangePasswordModal setNotification={setNotification} />
      </Grid>
      <SuccessNotification
        open={notification.open}
        handleClose={() => setNotification(oldState => ({ ...oldState, open: false }))}
        message={notification.message}
        severity={notification.severity}
      />
    </>
  );
}