interface Timeformat {
  toString:string
  hours:number
  minutes:number
  seconds:number
  timeInMillis:number
  wholeHours:string
}

const leadingZero = (data:number): string => `${data < 10 ? `0${data}` : data}`;

// const dateFormat = (dateString: Date | string | null): string => {

//   if (dateString === null) {
//     return "";
//   }
//   let date: Date;

//   if (typeof dateString === "string") {
//     date = new Date(dateString);
//     if (Number.isNaN(date.getFullYear())) {
//       date = new Date(Number(dateString));
//     }
//   }
//   else {
//     date = dateString;
//   }

//   if (date instanceof Date && Number.isNaN(date.getFullYear())) {
//     throw new Error('Invalid date format');
//   }

//   return  `${date.getFullYear()}-${leadingZero(date.getMonth() + 1)}-${leadingZero(date.getDate())}`;
// }

const dateFormat = (dateString: Date | string | null | undefined, english?: boolean): string => {

  if (dateString === null || dateString === undefined) {
      return "";
  }

  let date: Date;

  if (typeof dateString === "string") {
      date = new Date(dateString);
      if (Number.isNaN(date.getFullYear())) {
      date = new Date(Number(dateString));
      }
  }
  else {
      date = dateString;
  }

  if (date instanceof Date && Number.isNaN(date.getFullYear())) {
      throw new Error('Invalid date format');
  }

  return  english ? 
  `${leadingZero(date.getDate())}/${leadingZero(date.getMonth() + 1)}/${date.getFullYear()}` :
  `${date.getFullYear()}-${leadingZero(date.getMonth() + 1)}-${leadingZero(date.getDate())}`;
}

const setDateTimeToMidDay = ( date: string | Date ): Date => {
  // if (date instanceof Date) {
  //   date = dateFormat(date)
  // }
  const ymd = (date instanceof Date ? dateFormat(date) : date).split('-');
  // console.log({ymd})
  // Require dates with leading zero:
  if (ymd.length === 3 && ymd[0].length === 4 && ymd[1].length === 2 && ymd[2].length === 2) {
    // const dateInMillis = new Date(date).getMilliseconds() + 12 * 60 * 60 * 1000
    const dateInMillis = (new Date(ymd.join('-'))).getTime() + 12 * 60 * 60 * 1000
    // console.log({dateInMillis, date: new Date(dateInMillis), asdf: new Date(ymd.join('-')).getTime()})
    return new Date(dateInMillis)
  }
  throw new Error (`Format Error: ${ymd.join('-')} (from ${date}) is not a valid date format`)
}

// returns time as a string in the format "xx:xx" as well as hours, mins, secs
const timeFormat = (timeString: string): Timeformat => {
  const now = timeString.trim() === "" ? new Date() : new Date(timeString);
  const minutes = now.getMinutes();
  const hours = now.getHours();
  const seconds = now.getSeconds();
  const timeInMillis = 
  (
      (
          hours       * 60 + 
          minutes )   * 60 + 
          seconds )   * 1000;

  const toString = `${leadingZero(hours)}:${leadingZero(minutes)}`;
  const wholeHours = `${leadingZero(hours)}:00`;
      
  return { toString, hours, wholeHours, minutes, seconds, timeInMillis }
}

export { dateFormat, timeFormat, setDateTimeToMidDay };
export type { Timeformat };
