/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */

import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Relation } from "../_interfaces/OrderInterface";
import relationStrings from '../_strings/Relations.json';
import inputStrings from '../_strings/Input.json';

export interface RespondentData {
    value: {
        name: string
        email: string
        relation: Relation
        language: string | null
    },
    error?: string
    valid: boolean
    index: number
}

interface ChoosenRespondentInput {
    index: number
    disabled?: boolean
    language: string
    languages: string[]
    value: any
    handleInputValues: any
    handleValidation: any
    defaultName?: string
    defaultEmail?: string
    defaultLanguage?: string
    defaultRelation?: Relation
}

export const ChooseRespondentComponent = ({
    index, disabled, language, languages, defaultEmail, defaultLanguage, defaultName, defaultRelation, value, handleInputValues, handleValidation
}: ChoosenRespondentInput): JSX.Element => 
<ChooseRespondentHTMLComponent
        language={language}
        languages={languages}
        index={index}
        disabled={disabled}
        defaultEmail = {defaultEmail}
        defaultName = {defaultName}
        defaultLanguage = {defaultLanguage}
        defaultRelation = {defaultRelation}
        value = {value}
        handleInputValues = {event => handleInputValues(event, index)}
        handleValidation = {event => handleValidation(event, index)}
    />

ChooseRespondentComponent.defaultProps = {
    selected: false
}

export const ChooseRespondentHTMLComponent = ({
    language, index, languages, disabled, defaultName, defaultEmail, defaultLanguage, defaultRelation, value, handleInputValues, handleValidation
}: ChoosenRespondentInput): JSX.Element => 

    <Grid container>
    <Grid item xs={12} sm={6} lg={4} margin='16px 0px 16px 0px'>
        <TextField
            id={`name_${index}`}
            name='name'
            value={value?.name?.value || defaultName}
            onChange={handleInputValues}
            label={inputStrings[language].name}
            margin="normal"
            fullWidth
            disabled={disabled}
            inputProps={{ "data-test": "name-input" }}
            helperText={<span style={{ color:"red" }}>{value?.name?.error}</span>}
            onBlur={handleValidation}
        />

    </Grid>
    <Grid item xs={12} sm={6} lg={4} margin='16px 0px 16px 0px'>
        <TextField
            id={`email_${index}`}
            name='email'
            value={value?.email?.value || defaultEmail}
            onChange={handleInputValues}
            margin="normal"
            fullWidth
            label={inputStrings[language].email}
            disabled={disabled}    
            inputProps={{ "data-test": "email-input" }}
            helperText={<span style={{ color:"red" }}>{value?.email?.error}</span>}
            onBlur={handleValidation}
        />
    </Grid>
    <Grid item xs={12} sm={6} lg={2} margin='16px 0px 16px 0px'>
        <FormControl fullWidth margin='normal'>
            <InputLabel id={`relationLabel_${index}`}>{ inputStrings[language].relation }</InputLabel>
            <Select
                id={`relation_${index}`}
                name="relation"
                labelId={`relationLabel_${index}`}  
                inputProps={{ "data-test": "relation-input" }}
                value={value?.relation?.value || defaultRelation}
                disabled={disabled}
                onChange={handleInputValues}
                label={ inputStrings[language].relation }
            >
                <MenuItem value={Relation.subordinate}>{ relationStrings[language].subordinate }</MenuItem>
                <MenuItem value={Relation.colleague}>{ relationStrings[language].colleague }</MenuItem>
                <MenuItem value={Relation.superior}>{ relationStrings[language].superior }</MenuItem>
                <MenuItem value={Relation.other}>{ relationStrings[language].other }</MenuItem>
            </Select>
        </FormControl>
    </Grid>
    {
        languages.length > 1 &&
        <Grid item xs={12} sm={6} lg={2} margin='16px 0px 16px 0px'>
            <FormControl fullWidth margin='normal'>
                <InputLabel id={`languagLabel_${index}`}>{ inputStrings[language].language }</InputLabel>
                <Select
                    id={`language_${index}`}
                    name="language"
                    labelId={`languageLabel_${index}`}  
                    inputProps={{ "data-test": "language-input" }}
                    value={value?.language?.value || defaultLanguage}
                    disabled={disabled}
                    onChange={handleInputValues}
                    label={ inputStrings[language].language }
                >
                    <MenuItem value="swedish">{ inputStrings[language].swedish }</MenuItem>
                    <MenuItem value="english">{ inputStrings[language].english }</MenuItem>
                </Select>
            </FormControl>
        </Grid>    
    }
    { !disabled && 
        <Grid item xs={12} display={{lg:'none', xs:'box'}} component={Box} margin='16px 0px 16px 0px' >
            <hr/>
        </Grid>
    }
    </Grid>

ChooseRespondentComponent.defaultProps = {
    disabled: false,
    defaultName: '',
    defaultEmail: '',
    defaultLanguage: '',
    defaultRelation: '',
}
