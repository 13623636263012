import { Link } from "@mui/material"
import browserHistory from "../_helpers/History"

/*
Custom Link component to avoid top component reload on link clicks, which happens othervice with the current
router solution - reseting languageContext to default in the process.
Link components having a onClick-handler of their own does not need to use this one.
*/
export const SLink = ({href, children, cypressTag, newWindow}: { href:string, children:JSX.Element|string, cypressTag?:string, newWindow?: boolean }): JSX.Element => {

    const followLink = (event): void => {
        event.preventDefault()
        if (newWindow) {
            window.open(event.target.href, '_blank', 'noopener, noreferrer')
        }
        else {
            browserHistory.push(event.target.href)
        }
    }

    return <Link onClick={followLink} href={href} data-test={cypressTag}>{ children }</Link>
}

SLink.defaultProps = { cypressTag: undefined, newWindow: false }