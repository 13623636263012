import { Alert, AlertColor, Snackbar } from "@mui/material"
import { SyntheticEvent } from "react"

const SuccessNotification = ({ open, handleClose, message, severity, hideAfterXSeconds }: {
    open: boolean, 
    handleClose: (event: SyntheticEvent | Event, reason?: string | undefined) => void, 
    message: string,
    severity?: AlertColor
    hideAfterXSeconds?: number
}): JSX.Element =>

    <Snackbar
        open={open}
        data-test='notification'
        autoHideDuration={(hideAfterXSeconds || 3) * 1000}
        onClose={handleClose}
    >
        <Alert onClose={handleClose} severity={severity || "success"} sx={{ width: '100%' }}>
            {message}
        </Alert>
    </Snackbar>

SuccessNotification.defaultProps = {
    severity: 'success',
    hideAfterXSeconds: 3
}

export { SuccessNotification }