import { useContext } from "react";
import { Box, Typography } from "@mui/material";
import strings from '../_strings/CookiesPage.json';
import { LanguageContext } from "../_contexts/LanguageContext";

// export const CookiesPage = ( { language = "swedish" } : { language: string } ): JSX.Element => {
export const CookiesPage = (): JSX.Element => {
    
    const { language } = useContext(LanguageContext);

    return <div style={{marginTop: '10vh', maxWidth:'640px'}}>        
        <Box mt='20px'>
            <Typography variant="h5" align="center">Cookies</Typography>
            <Typography>{ strings[language].title }</Typography>
            <ul>
                <li>{ strings[language].firstPoint }</li>
                <li>{ strings[language].secondPoint }</li>
            </ul>
            <Typography><b>{ strings[language].warningFirstParagraph }</b></Typography>
            <Typography><b>{ strings[language].warningSecondParagraph }</b></Typography>
        </Box>
    </div>
}