import axios from "axios";

// const api = process.env.NODE_ENV === 'development' ? 'http://localhost:7001' : 'https://api.sleipner.nu';
const api = process.env.REACT_APP_API_URL;
if (api === undefined) {
    throw new Error('url to api is not set at fetcher');
}

export const fetcher = async (args: any): Promise<any> =>
    axios.post(
        `${api}/graphql`,
        { ...args },
        { headers: { "Content-Type": "application/json" } }
    )
    .then(res => res.data)
    .catch((error: Error) => {
        // const code = `${error}`.split("status code ")[1];
        // console.log('code')
        // console.log(code === '401') // 401 - unauthorized, 403 - forbidden
        const e = { ...error } as any;
        e.status = 403;
        throw e;
    });

// export const openFetcher = async (args: any): Promise<any> => 
//     axios.post(
//         `${api}/login`,
//         {
//             ...args
//         },
//         { headers: { 
//             "Content-Type": "application/json"
//         } }
//     )
//     .then(res => res.data)
//     .catch((error: Error) => {
//         const e = { ...error } as any;
//         e.status = 403;
//         throw e;
//     });