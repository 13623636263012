import { TextField } from "@mui/material"
import React, { useEffect } from "react"
import { checkEmailFormat, checkMaxLength, checkMinLength, checkPhoneFormat, checkPostalFormat, checkRequired, checkWeirdChars } from "../_helpers/Constraints"
import { Relation } from "../_interfaces"

export interface ValidatingTextData {
    value: string
    error?: string
    auto?: boolean
    valid: boolean 
}

// export interface ValidatingRelationData {
//     value: Relation
//     error?: string
//     auto?: boolean
//     valid: boolean 
// }


interface TextInputData {
    id: string
    data: ValidatingTextData
    setData: React.Dispatch<React.SetStateAction<ValidatingTextData>>
    prettyText: string
    name?: string
    dataTest?: string
    label?: string
    autoComplete?: string
    autoFocus?: boolean
    sx?: any
    type?: string
    inputRef?: React.MutableRefObject<HTMLInputElement>
    onKeyDown?: any
    disabled?: boolean
    required?: boolean
    min?: number
    max?: number
    checkForWeirdChars?: boolean
    checkEmail?: boolean
    checkPostal?: boolean
    checkPhone?: boolean
}


export const ValidatingInput = ({
    id, name, dataTest, required, label, autoComplete, autoFocus, sx, type, inputRef, data, setData, onKeyDown, disabled,
    min, max, prettyText, checkForWeirdChars, checkEmail, checkPhone, checkPostal
}: TextInputData): JSX.Element => {

    const { value, error, auto: autofill, valid } = data
    useEffect(() => {
        if (valid || error || autofill || min && value.length >= min) {
            handleInputValidation()
        }
    }, [value]);

    const handleInputValue = (event): void => {
        setData(oldState => {
            const v = event.target.value
            if (Math.abs(oldState.value.length - v.length) > 1) {
                return { ...oldState, value: v, auto: true }
            }
            return { ...oldState, value: v }
        })
    }

    const handleInputValidation = (): void => {
        try {
            if (required) checkRequired(value, prettyText)
            if (min) checkMinLength(value, min, prettyText)
            if (max) checkMaxLength(value, max, prettyText)
            if (checkForWeirdChars) checkWeirdChars(value, prettyText)
            if (checkEmail) checkEmailFormat(value)
            if (checkPostal) checkPostalFormat(value)
            if (checkPhone) checkPhoneFormat(value)
            setData(oldState => ({ ...oldState, value, auto: false, valid: true, error: undefined }))
        }
        catch (e: any) {
            setData(oldState => ({ ...oldState, error: e.message, auto: false, valid: false }))
        }
    }

    return (
    <TextField
        id={id}
        name={name}
        margin="normal"
        fullWidth
        sx={sx}
        // data-test={dataTest}
        required={required}
        value={value}
        onChange={handleInputValue}
        label={label}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        inputRef={inputRef}
        inputProps={{ "data-test": dataTest }}
        helperText={<span style={{ color:"red" }}>{error}</span>}
        onBlur={handleInputValidation}
        type={type}
        onKeyDown={onKeyDown}
        disabled={disabled}
    />
    )
}

ValidatingInput.defaultProps = {
    label: '',
    autoComplete: undefined,
    autoFocus: false,
    required: false,
    sx: undefined,
    type: 'text',
    inputRef: undefined,
    dataTest: undefined,
    onKeyDown: undefined,
    disabled: undefined,
    name: '',
    min: undefined,
    max: undefined,
    checkForWeirdChars: undefined,
    checkEmail: undefined,
    checkPostal: undefined,
    checkPhone: undefined
}