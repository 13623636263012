import { Grid, TextareaAutosize, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Email } from "../_interfaces/EmailInterface";
import { api } from "../_services/apiServices";
import { LoadingFormButton } from "./LoadingFormButton";
import { SuccessNotification } from "./SuccessNotification";
import { useForm } from "../_helpers/useForm";
import { License } from "../_interfaces";

const EmailForm = ({ email, setEmails, license, textColor }: {
  email: Email,
  setEmails: React.Dispatch<React.SetStateAction<Email[]>>,
  license: License,
  textColor: (email: any) => string | undefined
}): JSX.Element => {
  // const { 
  //   handleInputValue,         
  //   handleEmailFormSubmit,
  //   formIsValid,
  //   errors,
  //   values,
  //   setValues
  // } = useFormControls(email); // setting initial values to the values supplied

  const { description, subject, body, id, language } = email;

  const { values, valid, handleInputValidation, handleInputValues } = useForm({
    subject: { value: email.subject, error: "" },
    body: { value: email.body, error: "" }
  })

  useEffect(() => {
    handleInputValues({target: { name: "subject", value: email.subject }})
    handleInputValues({target: { name: "body", value: email.body }})
  }, [email])
  
  // console.log(values, email)
  // const { sendTestEmail } = api();

  // const [savedNotification, toggleSaved] = useState(false);
  // const [sentNotification, toggleSent] = useState(false);
  const [notification, toggleNotification] = useState({ message: 'Sparat!', open: false });
  // const [sentNotification, toggleSent] = useState(false);

  // const [html, setHtml] = useState(body);

  // const [placeHolders, setPlaceHolders] = useState(['$$klient$$','$$sista svarsdatum$$','$$dagar kvar till sista svarsdatum$$']);
  // const [placeHolders, setPlaceHolders] = useState([]);

  // useEffect(() => {
  //   setValues((prev:Email) => ({
  //     ...prev,
  //     description,
  //     subject,
  //     body,
  //     id,
  //     language
  //   }));
  // },[email]);

  // selenium addon seems to add a header to the iframe at load time, so it's hard to get to here..
  // remove seleniums addon in the iframe:
  // const iframeRef: React.MutableRefObject<HTMLIFrameElement | undefined> = useRef(); 
  // const iframeRef = useRef<any>(); 
  // useEffect(() => {
  //   console.log(iframeRef.current);
  // },[]);
  // console.log({values})

  const handleSubmit = async (): Promise<void> => {
      api().sendEmailForm({ subject: values.subject.value, body: values.body.value, description, id, language, license: license.key })
      .then(response => {
        // console.log({response})
        // if (response.errors) {
        //   console.log(response.errors)
        //   toggleNotification({ message:"FEL!", open:true });
        // }
        if (response && response.id && response.id !== '') {
          setEmails((prev:Email[]) => {
            const newArray = prev.slice();
            const index = newArray.findIndex(e => e.id === response.id && e.language === response.language);
            if (index !== -1) {
              newArray[index] = response;
            }
            return newArray;
          })
          toggleNotification({ message:"Sparat!", open:true });
        }
        else {
          // console.log('no response, no error')
          console.log((response as any).errors)
          toggleNotification({ message:"FEL!", open:true });
        }
    })
  }

  const handleCloseNotification = (event: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      toggleNotification({ message: notification.message, open: false });
      return;
    }
    toggleNotification({ message: notification.message, open: false });
  }

  // const testmail = (): void => {
  //   sendTestEmail({
  //     id:values.id,
  //     description:values.description,
  //     subject:values.subject,
  //     body:values.body
  //   } as Email)
  //   .then((data) => {
  //     if (data && data.length === 0) {
  //       toggleNotification({ message: "Skickat!", open: true });
  //     }
  //     else if (data && data.length > 0) {
  //       alert(`Något gick fel från vårt håll när eposten skulle skickas. `+
  //       `Kunde ej skicka email till: ${data.map((e: string) => `\n${e}`)}\n\n.`+
  //       `Så ska det inte vara. Hör gärna av dig till support så `+
  //       `försöker vi återskapa problemet och felsöka vad som kan ha hänt.`);  
  //     }
  //   }).catch(error => {
  //     const msg = error.length > 0 ? error.map((e:any) => `${e} \n\n`) : error;
  //     alert(msg)
  //   });
  // }

  return (
    <>
      {/* <TextField 
        id='description'
        name='description'
        label='Namn på meddelandet. Till exempel "Påminnelse att besvara kartläggning"'
        value={values.description}
        helperText={<span style={{ color:"red" }}>{errors.description}</span>}
        onChange={handleInputValue} 
        fullWidth
        margin="normal"
      /> */}
      <TextField 
        id='subject'
        name='subject'
        label='Ämne/rubrik på meddelandet'
        value={values.subject.value}
        // sx={{ input: { color: email.tag === 'default' ? 'blue': 'black' }}}
        // sx={textColor()}
        // sx={{ input: { color: textColor(email) }}}
        helperText={<span style={{ color:"red" }}>{ values.subject.error }</span>}
        onChange={handleInputValues} 
        fullWidth
        margin="normal"
      />
      <Grid container style={{ width:'100%', marginTop:'16px', marginBottom:'8px' }}>
        <Grid 
          item 
          xs={12}
          // sm={12}
          sm={values.body && values.body.value?.startsWith('<html>') ? 6 : 12}
        >
          <TextareaAutosize
            id='body'
            name='body'
            placeholder='Mailtext...'
            value={values.body.value}
            minRows={20}
            onChange={handleInputValues} 
            // style={{ width:'100%', height:'100%', color: textColor(email) }}
            style={{ width:'100%', height:'100%' }}
            />
          
        </Grid>
        {values.body && values.body.value?.startsWith('<html>') &&
          <Box 
            component={Grid}
            style={{ minHeight:'306px', border:'1px solid grey' }}
            item 
            sm={6}
            xs={12}
            display={{xs:values.body.value?.startsWith('<html>') ? 'block' : 'none'}}
          >
            <Typography style={{ height:'10%', textAlign:'center', width:'100%' }}>Förhandsgranska html:</Typography>
            <iframe 
              sandbox=''
              srcDoc={values.body} 
              title='previewEmail' 
              id='previewEmail'
              style={{ width:'100%', height:'90%' }}
            />
          </Box>
      }
      </Grid>
      {/* <Typography variant='h6'>
        (Så småningom kommer det dyka upp en lista på VILKA ord som går att byta ut...)
      </Typography> */}

      {/* <Button 
        sx={{width:'100%', padding:'2vh', marginTop:'16px', marginBottom:'8px'}}
        variant='outlined' 
        onClick={testmail}
      >
        Maila mig! (För att se hur det ser ut i inkorgen)
      </Button>
*/}
      <LoadingFormButton
        sx={{ padding:'1vh', marginTop:'16px', marginBottom:'8px' }}
        disabled={!valid}
        onClick={handleSubmit}
      >
        Spara
      </LoadingFormButton>
      <SuccessNotification
        open={notification.open}
        handleClose={handleCloseNotification}
        message={notification.message}
      />
    </>
  );
}

export { EmailForm };