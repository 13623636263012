import { SyntheticEvent, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { EmailPage } from './EmailPage';
import { QuestionsPage } from './QuestionsPage';
import { License } from '../_interfaces';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
    >
      {value === index && (
        <Container>
            <Box sx={{ pt: 3 }}>
                {children}
            </Box>
        </Container>
      )}
    </div>
  );
}

const EditPage = ({ license }: { license: License }): JSX.Element => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  return (
    <Box sx={{ paddingTop:'10vh', width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="License edits">
          <Tab label="Epost" />
          {/* <Tab label="Frågor" /> */}
          {/* <Tab label="Rapport" /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} >
        <EmailPage license={license} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <QuestionsPage  license={license} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Typography>
          Här! Ska man kunna förhandsgranska rapporten så småningom, och kanske eventuellt göra vissa ändringar.
          Men det ligger längre fram i tiden &#128523; <br /> <br /> 
          Är det något särskilt du behöver göra med rapporten, maila mig så kollar jag om det går att lösa på ett
          snabbare sätt.
          <br/><br/>/Anders
        </Typography>
      </TabPanel>
    </Box>
  );
}

export { EditPage }