import { useContext } from "react";
import { Typography } from "@mui/material";
import { User } from "../_interfaces";
import strings from "../_strings/ThanksPage.json"
import { LanguageContext } from "../_contexts/LanguageContext";

// export const ThanksPage = ( { user, language }: { user: User, language: string }): JSX.Element =>
//     <Typography mt='10vh' variant='h5'>{ strings[language].thanks }</Typography>

export const ThanksPage = ( { user }: { user: User }): JSX.Element => {
    const { language } = useContext(LanguageContext)
    return <Typography mt='10vh' variant='h5'>{ strings[language].thanks }</Typography>
}
