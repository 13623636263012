import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@mui/material";

export const LicenseComponent = ({ license, handleAdd, handleDelete, disabled }: { 
    license: any
    // newLicense: License | undefined
    // discountPrice: number
    // discountLimit: number
    // userLicense: any
    // remaining: number
    // shoppingcart: number
    disabled: boolean
    handleAdd: any
    handleDelete: any 
}): JSX.Element => 
    <Grid container direction="row" alignItems="center" justifyContent="center">
        <Grid item md={3} sm={4} xs={12} sx={{ textAlign: { xs:"center", sm:"left" }}}>
            { license.label} { license.type} <Typography fontSize=".8em" component="span" color="red">
                ( {license.price} kr{license.discountPrice ? ` / ${license.discountPrice} kr` : ''} )
                </Typography>
        </Grid>
        <Grid item md={4} sm={4} xs={12} sx={{ textAlign:"center" }}>
            { license.pcs } + <Typography component="span" color="primary"><b>{ license.added }</b></Typography> = { license.pcs + license.added }
        </Grid>
        <Grid item md={2} sm={2} xs={6} display="flex" sx={{ justifyContent:{ md: "center", xs: "right"}}}>
            <Button 
                disabled={disabled}
                variant="text" 
                startIcon={<FontAwesomeIcon icon="plus-circle" />} 
                onClick={() => handleAdd({key: license.key, added: 1})} >
                    1
            </Button>
            <Button 
                disabled={disabled}
                sx={{ display: { xs: "none", sm: "flex" }}}
                variant="text" 
                startIcon={<FontAwesomeIcon icon="plus-circle" />} 
                onClick={() => handleAdd({key: license.key, added: license.discountLimit})} >
                    {license.discountLimit}
            </Button>
        </Grid>
        <Grid item md={2} sm={2} xs={6} display="flex" sx={{ justifyContent:{ md: "center", xs: "left"}}}>
            <Button 
                disabled={disabled}
                variant="text" 
                startIcon={<FontAwesomeIcon icon="minus-circle" />} 
                onClick={() => handleDelete({key: license.key, added: 1})} >
                    1
            </Button>
            <Button 
                disabled={disabled}
                sx={{ display: { xs: "none", sm: "flex" }}}
                variant="text" 
                startIcon={<FontAwesomeIcon icon="minus-circle" />} 
                onClick={() => handleDelete({key: license.key, added: license.discountLimit})} >
                    {license.discountLimit}
            </Button>
        </Grid>
    </Grid>
    
    // <Typography variant='h6' mt='2vh' mb='2vh' align='center'>{license.label} 360</Typography>
    // <Typography>
    //     Lägg till licenser för att kunna beställa {license.label} 360.
    //     {license.price > 0 ? 
    //     `Priset ex moms är ${license.price} kr per licens. Vid köp av 10 licenser eller fler vid ett och
    //     samma tillfälle utgår ${discount} % rabatt.` : ""
    //     }
    // </Typography>
    // <TextField
    //     margin="normal"
    //     data-test="license-input"
    //     required
    //     type="number"
    //     // fullWidth
    //     id="license"
    //     label="Antal licenser: "
    //     defaultValue={0}
    //     // defaultValue={licenses}
    //     // onChange={handleLicense}
    //     name="license"
    //     // autoComplete="email"
    //     // onKeyPress={(event:any) => submitOnKeypress(event)}
    //     // autoFocus
    // />