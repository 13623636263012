import { CircularProgress, IconButton, Tooltip } from "@mui/material"
import { useEffect, useRef, useState } from "react";

interface ILoadingButton {
    children: string | JSX.Element
    onClick: any
    disabled?: boolean
    dataTest?: string
}

interface ILoadingIconButton extends ILoadingButton {
    tooltip: string
}

interface IHtmlLoadingIconButton extends ILoadingButton {
    loading: boolean,
    tooltip: string
}

export const LoadingIconButton = (props: ILoadingIconButton): JSX.Element => {
    const { onClick: handleSubmit, ...htmlProps } = props;
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => { mounted.current = false }
    }, []);

    const onClick = async (values?: any): Promise<void> => {
        setIsSubmitting(true)
        handleSubmit(values).finally(() => {
            setTimeout(() => {
                if (mounted.current) {
                    setIsSubmitting(false)
                }    
            }, 3000)
        })
    }
    return HtmlLoadingIconButton({ ...htmlProps, onClick, loading: isSubmitting })
}

const HtmlLoadingIconButton = 
({ children, disabled, onClick, loading, dataTest, tooltip }: IHtmlLoadingIconButton):
JSX.Element =>
    <Tooltip title={tooltip}>
        <span>
            { loading ?
            <IconButton disabled>
                <CircularProgress size={16} sx={{ color: 'rgba(0, 0, 0, 0.26)' }}/>
            </IconButton>
            : 
            <IconButton 
                disabled={disabled}
                data-test={dataTest}
                onClick={onClick}
            >
                {children}
            </IconButton>
            }
        </span>
    </Tooltip>

LoadingIconButton.defaultProps = {
    variant: "contained",
    sx: {},
    disabled: false,
    dataTest: 'submit',
}

HtmlLoadingIconButton.defaultProps = LoadingIconButton.defaultProps