import { useCallback, useState } from "react"
import { checkEmailFormat, checkMaxLength, checkMinLength, checkPhoneFormat, checkPostalFormat, checkRequired, checkWeirdChars, constraints } from "./Constraints";

export const useForm = ( defaults: any, checkCallback?: any ): {
    handleInputValidation: (event: any, index?: number) => void
    handleInputValues: (event: any, index?: number) => void
    // error: string | undefined;
    valid: boolean
    // values: any []
    values: any
} => {

    // const [error, setError] = useState<string | undefined>(undefined);
    const [valid, setValid] = useState(false);
    // const [values, setValues] = useState<any[]>(defaults || [])
    const [values, setValues] = useState<any>(defaults || {})

    const handleInputValues = useCallback((event, index?: number): void => {
        if (event.persist) {
            event.persist()
        }
        if (index !== undefined) {
            let validCounter = 0;
            setValues(oldState => oldState.map((v,i) => {
                if (i === 0) {
                    validCounter = 0;
                }
                if (i === index) {
                    const valuePasted = Math.abs(v[event.target.name].value.length - event.target.value.length) > 1;
                    const stateWithNewValueAdded = { ...v, [event.target.name]: { value: event.target.value, error: v[event.target.name]?.error } }
                    // validate on pasted value, if there is an error from before, if the min length of values have been reached
                    // if (
                    //     valuePasted || 
                    //     (v[event.target.name].error && v[event.target.name].error !== '') ||
                    //     (constraints[event.target.name]?.min && constraints[event.target.name].min <= event.target.value.length)
                    // ){
                    if (valuePasted) {
                        const validatedState = handleValidations<typeof stateWithNewValueAdded>(stateWithNewValueAdded)
                        if (validatedState.valid) {
                            validCounter += 1
                        }
                        if (i === oldState.length - 1) {
                            setValid(validCounter >= 2)
                        }
                        return { 
                            ...stateWithNewValueAdded, 
                            [event.target.name]: { 
                                value: stateWithNewValueAdded[event.target.name].value,
                                error: validatedState[event.target.name].error
                            },
                            valid: validatedState.valid 
                        }
                    }
                    return stateWithNewValueAdded
                }
                if (v.valid) {
                    validCounter += 1
                }
                if (i === oldState.length - 1) {
                    setValid(validCounter >= 2)
                }
                return v;
            }))
        }
        else {
            setValues(oldState => {
                const valuePasted = Math.abs((oldState[event.target.name].value?.length || 0) - (event.target.value?.length || 0)) > 1;
                const stateWithNewValueAdded = { ...oldState, [event.target.name]: { value: event.target.value, error: oldState[event.target.name]?.error } }
                if (valuePasted) {
                    const validatedState = handleValidations<typeof stateWithNewValueAdded>(stateWithNewValueAdded)
                    setValid(validatedState.valid)
                    return { 
                        ...stateWithNewValueAdded, 
                        [event.target.name]: { 
                            value: stateWithNewValueAdded[event.target.name].value,
                            error: validatedState[event.target.name].error
                        }
                    }
                }
                return stateWithNewValueAdded
            })
        }
    }, [])

    const handleValidations = <T>(value: any): T => {
        let newObject = { ...value };
        let noError = true;
        const keys = Object.keys(value)
        keys.forEach((key, index) => {
            const constraint = constraints[key]
            if (constraint) {
                const e = handleValidation(constraint, value[key].value) || checkCallback && checkCallback(key, value)
                if (e && constraint.required) {
                    noError = false;
                }
                newObject = { ...newObject, [key]: { ...newObject[key], error: e } }
            }
            if (index === keys.length - 1) {
                newObject = { ...newObject, valid: noError }
            }
        })
        return newObject;
    }

    const handleValidation = (constraint: any, value: string): string | undefined => {
        try {
            if (constraint.required) checkRequired(value, constraint.prettyText)
            if (constraint.min) checkMinLength(value, constraint.min, constraint.prettyText)
            if (constraint.max) checkMaxLength(value, constraint.max, constraint.prettyText)
            if (constraint.checkForWeirdChars) checkWeirdChars(value, constraint.prettyText)
            if (constraint.checkEmail) checkEmailFormat(value)
            if (constraint.checkPostal) checkPostalFormat(value)
            if (constraint.checkPhone) checkPhoneFormat(value)
        }
        catch (e: any) {
            return e.message
        }
        return undefined
    }

    const handleInputValidation = ( event: any, index?: number ): void => {
        if (event.persist) {
            event.persist()
        }
        if (index !== undefined) {
            let validCounter = 0;
            setValues(oldState => oldState.map((v,i) => {
                if (i === 0) {
                    validCounter = 0;
                }
                if (i === index) {
                    const stateWithNewValueAdded = { ...v, [event.target.name]: { value: event.target.value, error: v[event.target.name]?.error } }
                    const validatedState = handleValidations<typeof stateWithNewValueAdded>(stateWithNewValueAdded)
                    if (validatedState.valid) {
                        validCounter += 1
                    }
                    if (i === oldState.length - 1) {
                        setValid(validCounter >= 2)
                    }
                    return { 
                        ...stateWithNewValueAdded, 
                        [event.target.name]: { 
                            value: stateWithNewValueAdded[event.target.name].value,
                            error: validatedState[event.target.name].error
                        },
                        valid: validatedState.valid 
                    }
                }
                if (v.valid) {
                    validCounter += 1
                }
                if (i === oldState.length - 1) {
                    setValid(validCounter >= 2)
                }
                return v
            }))
        }
        else {
            setValues(oldState => {
                const stateWithNewValueAdded = { ...oldState, [event.target.name]: { value: event.target.value, error: oldState[event.target.name]?.error } }
                const validatedState = handleValidations<typeof stateWithNewValueAdded>(stateWithNewValueAdded)
                setValid(validatedState.valid)
                return { 
                    ...stateWithNewValueAdded, 
                    [event.target.name]: { 
                        value: stateWithNewValueAdded[event.target.name].value,
                        error: validatedState[event.target.name].error
                    }
                }
            })
        }
    }

    // const handleInputValidationOff = (value?: any): void => {
    //     const data = value || values
    //     // console.log('validating...')
    //     // console.log(data) // {email: 'asd'}
    //     const keys = Object.keys(data)
    //     const c = constraints[keys[0]]
    //     const cKeys = Object.keys(c)

    //     // console.log({ keys, value: data[keys[0]]})
    //     // console.log(cKeys)

    //     // keys.forEach(key => {
    //     //     try {
    //     //         const v = data[key]
    //     //         if (cKeys.includes('required')) checkRequired(v, c.prettyText)
    //     //         if (cKeys.includes('min')) checkMinLength(v, c.min, c.prettyText)
    //     //         if (cKeys.includes('max')) checkMaxLength(v, c.max, c.prettyText)
    //     //         if (cKeys.includes('checkForWeirdChars')) checkWeirdChars(v, c.prettyText)
    //     //         if (cKeys.includes('checkEmail')) checkEmailFormat(v)
    //     //         if (cKeys.includes('checkPostal')) checkPostalFormat(v)
    //     //         if (cKeys.includes('checkPhone')) checkPhoneFormat(v)
    //     //     }
    //     //     catch (e: any) {
    //     //         return e.message
    //     //     }    
    //     // })
    //     // try {
    //     //     const v = data[key]
    //     //     if (required) checkRequired(v, prettyText)
    //     //     if ('min' in cKeys) checkMinLength(v, min, prettyText)
    //     //     if (max) checkMaxLength(v, max, prettyText)
    //     //     if (checkForWeirdChars) checkWeirdChars(v, prettyText)
    //     //     if (checkEmail) checkEmailFormat(v)
    //     //     if (checkPostal) checkPostalFormat(v)
    //     //     if (checkPhone) checkPhoneFormat(v)
    //     //     //  Data(oldState => ({ ...oldState, value, auto: false, valid: true, error: undefined }))
    //     //     setAutofill(false)
    //     //     setValid(true)
    //     //     setError(undefined)
    //     // }
    //     // catch (e: any) {
    //     //     // setData(oldState => ({ ...oldState, error: e.message, auto: false, valid: false }))
    //     //     setAutofill(false);
    //     //     setError(e.message);
    //     //     setValid(false);
    //     // }
        
    //     // inputData.map((input, index) => {
    //     //     const { prettyText, required, min, max, checkForWeirdChars, checkEmail, checkPostal, checkPhone } = input;
    //     //     try {
    //     //         if (!prettyText) {
    //     //             throw new Error('No pretty error label for input field')
    //     //         }
    //     //         const value = values[index].value
    //     //         if (required) checkRequired(value, prettyText)
    //     //         if (min) checkMinLength(value, min, prettyText)
    //     //         if (max) checkMaxLength(value, max, prettyText)
    //     //         if (checkForWeirdChars) checkWeirdChars(value, prettyText)
    //     //         if (checkEmail) checkEmailFormat(value)
    //     //         if (checkPostal) checkPostalFormat(value)
    //     //         if (checkPhone) checkPhoneFormat(value)
    //     //         //  Data(oldState => ({ ...oldState, value, auto: false, valid: true, error: undefined }))
    //     //         setAutofill(false)
    //     //         setValid(true)
    //     //         setError(undefined)
    //     //     }
    //     //     catch (e: any) {
    //     //         // setData(oldState => ({ ...oldState, error: e.message, auto: false, valid: false }))
    //     //         setAutofill(false);
    //     //         setError(e.message);
    //     //         setValid(false);
    //     //     }
    //     // })
    // }

    return { handleInputValidation, handleInputValues, valid, values }

    // return (
    // <TextField
    //     id={id}
    //     name={name}
    //     margin="normal"
    //     fullWidth
    //     sx={sx}
    //     // data-test={dataTest}
    //     required={required}
    //     value={value}
    //     onChange={handleInputValue}
    //     label={label}
    //     autoComplete={autoComplete}
    //     autoFocus={autoFocus}
    //     inputRef={inputRef}
    //     inputProps={{ "data-test": dataTest }}
    //     helperText={<span style={{ color:"red" }}>{error}</span>}
    //     onBlur={handleInputValidation}
    //     type={type}
    //     onKeyDown={onKeyDown}
    //     disabled={disabled}
    // />
    // )
}

useForm.defaultProps = {
    required: false,
    min: undefined,
    max: undefined,
    checkForWeirdChars: undefined,
    checkEmail: undefined,
    checkPostal: undefined,
    checkPhone: undefined
}