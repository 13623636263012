import { LoadingButton } from "@mui/lab"
import { Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

interface ILoadingButton {
    children: string | JSX.Element
    onClick: any
    disabled?: boolean
    variant?: "contained" | "text" | "outlined"
    sx?: Record<string, unknown>
    dataTest?: string
    helperText?: string
}

interface ILoadingFormButton extends ILoadingButton {
    triggerClick?: boolean
    setTriggerClick?: React.Dispatch<React.SetStateAction<boolean>>
}

interface IHtmlLoadingFormButton extends ILoadingButton {
    loading: boolean
}

export const LoadingFormButton = (props: ILoadingFormButton): JSX.Element => {
    const { onClick: handleSubmit, triggerClick, setTriggerClick, ...htmlProps } = props;
    const [ isSubmitting, setIsSubmitting ] = useState(triggerClick || false);
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => { mounted.current = false }
    }, []);

    useEffect(() => {
        // mounted.current = true;
        if (triggerClick) {
            onClick().then(() => setTriggerClick && setTriggerClick(false))
        }
        // return () => { mounted.current = false }
    }, [triggerClick]);

    const onClick = async (values?: any): Promise<void> => {
        setIsSubmitting(true)
        handleSubmit(values).finally(() => {
            setTimeout(() => {
                if (mounted.current) {
                    setIsSubmitting(false)
                }    
            }, 500)
        })
    }
    // console.log(props)
    return HtmlLoadingFormButton({ ...htmlProps, onClick, loading: isSubmitting })
}

const HtmlLoadingFormButton = (
    { children, disabled, onClick, loading, variant, sx, dataTest, helperText }: IHtmlLoadingFormButton
    ): JSX.Element => 
    <>
    {/* <Grid container>
        <Grid sm={12} item> */}
            <LoadingButton
                fullWidth
                data-test={dataTest}
                disabled={disabled}
                variant={variant}
                sx={sx}
                onClick={onClick}
                loading={loading}
            >
                {children}
            </LoadingButton>
            {helperText && <Typography color="red">{helperText}</Typography>}
        {/* </Grid>
    </Grid> */}
    </>
LoadingFormButton.defaultProps = {
    variant: "contained",
    // sx: { padding:'1vh', marginTop:'16px', marginBottom:'8px'},
    sx: {},
    disabled: false,
    dataTest: 'submit'
}

HtmlLoadingFormButton.defaultProps = LoadingFormButton.defaultProps