import { AlertColor, Box, Button, FormControlLabel, FormGroup, Modal, Switch, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { LoadingFormButton } from "./LoadingFormButton";
import { modalStyle } from "../_styles/ModalStyle";
import { api } from "../_services/apiServices";
import { useForm } from "../_helpers/useForm";

export const ChangePasswordModal = ({ setNotification }: { 
  setNotification: React.Dispatch<React.SetStateAction<{ open: boolean, message: string, severity: AlertColor }>>}): JSX.Element => {

  const [ passwordType, setPasswordType ] = useState('password')

  const defaults = {
    password: { error: "", value:  "" },
    oldPassword: { error: "", value:  "" },
    confirmPassword: { error: "", value: "" }
  }

  // validation occurs on values not yet set. That is why newValues are submitted as a parameter here
  const extraCheck = (key: string, newValues: any): string | undefined => {
    const { [key]: { value } } = newValues
    if (key === 'confirmPassword') {
        if (value !== newValues.password.value) {
            return `Lösenordet stämmer inte med det ovan`
        }
    }
    if (key === 'password') {
      if (newValues.confirmPassword.value !== "" && value !== newValues.confirmPassword.value) {
          return `Lösenordet stämmer inte med det nedan`
      }
    }
    return undefined
  }

  const { values, valid, handleInputValidation, handleInputValues } = useForm(defaults, extraCheck)

  const oldPasswordRef = useRef<HTMLInputElement>();

  const handleChangePasswordButtonClicked = async (): Promise<boolean> => 
    api().sendChangePasswordForm(values.password.value, values.oldPassword.value)
    .then(({ status, errors }) => {
      if (status === 'ok') {
        setNotification({ open: true, message: "Ditt lösenord har uppdaterats", severity: 'success' })
        handleClose()
      }
      else {
        setNotification({ open: true, message: "Ditt lösenord kunde inte uppdateras", severity: 'error' })
      }
      return true;
    })

  const [open, setOpen] = useState(false);
  const handleOpen = (): void => {
    setOpen(true);
    setTimeout(() => {
      const oldPasswordField = oldPasswordRef.current;
      if (oldPasswordField) {
        setTimeout(() => oldPasswordField.focus(), 300);
      }  
    }, 50)
  }
  const handleClose = (): void => setOpen(false);
    
  return (
    <>
      <Button onClick={handleOpen} data-test="change-password-button" fullWidth variant="contained" sx={{ padding:'1vh' }} >Byt lösenord</Button>
      <Modal
        open={open}
        data-test='forgotten-password-modal'
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField 
              name='oldPassword'
              inputRef={oldPasswordRef}
              inputProps={{ "data-test": "old-password-input" }}
              label='Tidigare lösenord'
              margin='normal'
              helperText={<span style={{ color:"red" }}>{values.oldPassword.error}</span>}
              onChange={(e) => {
                handleInputValues(e);
                handleInputValidation(e);
              }}
              type='password'
              defaultValue={values.oldPassword.value}
              fullWidth
            />
            <TextField 
              name='password'
              inputProps={{ "data-test": "password-input" }}
              label='Nytt lösenord'
              margin='normal'
              helperText={<span style={{ color:"red" }}>{values.password.error}</span>}
              onChange={(e) => {
                handleInputValues(e);
                handleInputValidation(e);
              }}
              type={passwordType}
              defaultValue={values.password.value}
              fullWidth
            />
            <TextField 
              name='confirmPassword'
              inputProps={{ "data-test": "confirm-password-input" }}
              label='Upprepa nytt lösenord'
              margin='normal'
              helperText={<span style={{ color:"red" }}>{values.confirmPassword.error}</span>}
              onChange={(e) => {
                handleInputValues(e);
                handleInputValidation(e);
              }}
              type={passwordType}
              defaultValue={values.confirmPassword.value}
              fullWidth
            />
            <FormGroup>
              <FormControlLabel 
                control={<Switch />} 
                label="Visa lösenord"
                checked={passwordType === "text"}
                onChange={() => setPasswordType(passwordType === "password" ? "text" : "password")}
              />
            </FormGroup>

            <LoadingFormButton
              sx={{ padding:'1vh', marginTop:'16px', marginBottom:'8px' }}
              disabled={!valid}
              onClick={handleChangePasswordButtonClicked}
            >
              Spara
            </LoadingFormButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
}