export const constraints: { [key: string] : {
    min: number, max:number, required:boolean, prettyText:string, checkForWeirdChars?:boolean,
    checkEmail?:boolean, checkPostal?: boolean, checkPhone?:boolean
} } = {
    "email":                { min:8,    max:64,     required:true,      prettyText:"Epostadressen", checkEmail: true },
    "language":             { min:7,    max: 16,    required:true,      prettyText:"Språk", checkForWeirdChars: true },
    "newAccountEmail":      { min:8,    max:64,     required:true,      prettyText:"Epostadressen", checkEmail: true },
    "name":                 { min:2,    max:40,     required:true,      prettyText:"Namnet", checkForWeirdChars: true },
    "firstName":            { min:2,    max:40,     required:true,      prettyText:"Förnamnet", checkForWeirdChars: true },
    "secondName":           { min:2,    max:40,     required:true,      prettyText:"Efternamnet", checkForWeirdChars: true },
    "password":             { min:8,    max:64,     required:true,      prettyText:"Lösenordet" },
    "oldPassword":          { min:8,    max:64,     required:true,      prettyText:"Lösenordet" },
    "confirmPassword":      { min:8,    max:64,     required:true,      prettyText:"Lösenordet" },
    "company":              { min:0,    max:64,     required:false,     prettyText:"Företagsnamnet" },
    "description":          { min:0,    max:256,    required:false,     prettyText:"Beskrivningen" },
    "subject":              { min:2,    max:256,    required:true,      prettyText:"Ämnet" },
    "body":                 { min:2,    max:6000,   required:true,      prettyText:"Meddelandet" },
    "questionBody":         { min:2,    max:512,    required:true,      prettyText:"Frågan" },
    "category":             { min:0,    max:32,     required:false,     prettyText:"Namnet på kategorin"},
    "city":                 { min:2,    max:64,     required:true,      prettyText:"Postorten", checkForWeirdChars: true },
    // "phone":                { min:8,    max:16,     required:true,      prettyText:"Telefonnumret", checkPhone: true },
    "phone":                { min:8,    max:16,     required:false,      prettyText:"Telefonnumret", checkPhone: true },
    "address":              { min:2,    max:64,     required:true,      prettyText:"Adressen"},
    "license":              { min:2,    max:128,    required:true,      prettyText:"Licensnyckeln"},
    "postal":               { min:0,    max:6,      required:true,      prettyText:"Postnumret", checkPostal: true } // max 6 to allow one space
}

export const checkRequired = (value: string, name: string): void => {
    if (typeof value === 'undefined' || value === null || value === "")
        throw new Error(`${name} är obligatorisk`)
}

export const checkMinLength = (value: string, min: number, name: string): void => {
    if (value.length < min)
        throw new Error(`${name} är för kort. Det ska innehålla mer än ${min} tecken.`)
}

export const checkMaxLength = (value: string, max: number, name: string): void => {
    if (value.length > max)
    throw new Error(`${name} är för långt. Det får innehålla max ${max} tecken.`)
}

export const checkWeirdChars = (value: string, name: string): void => {
    if (/[`!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/.test(value))
        throw new Error(`${name} innehåller tecken som inte förväntas finnas i ett namn`)
}

export const checkEmailFormat = (value: string): void => {        
    const formatChecksOut = (() => {
        let error = "";
        let correctComa = "";

        let ampisthere = false;
        let spacesthere = false;
    
        let textbeforeamp = false;
        let textafteramp = false;
        let dotafteramp = false;
        let othererror = false;

        for(let i = 0; i < value.length; i+=1) {

            if(value.charAt(i) === '@') {
                if(ampisthere)
                    othererror = true;
  
                ampisthere = true;
            }
            else if(!ampisthere)
                textbeforeamp = true;
  
            else if(value.charAt(i) === '.') {
                dotafteramp = true;                    
            }
            else
                textafteramp = true;

            if(value.charAt(i) === ' ')
                spacesthere = true;

            if(value.charAt(i) === ',') {
                correctComa += '.';

                // maybe actually not true, but gives a better error message this way
                dotafteramp = ampisthere ? true : dotafteramp; 
            }
            else
                correctComa += value.charAt(i);
        }
  
        if(spacesthere || !ampisthere || !textafteramp || !textbeforeamp || !dotafteramp || othererror || correctComa !== value) {            

            // first check for all errors except comas
            error = (spacesthere || !ampisthere || !textafteramp || !textbeforeamp || !dotafteramp || othererror) ?
            // "Epostadressen får ej innehålla komma eller mellanslag" :
                "Epostadressen har fel format" :
                `Epostadressen har fel format. Menade du '${correctComa}'?`;
            }
        return error;
    })();

    if (formatChecksOut !== "")
        throw new Error(formatChecksOut)
}

export const checkPostalFormat = (value: string): void => {
    const trimmedPostal = value.replace(/\s+/g,'');
    if (trimmedPostal.length !== 5) {
        throw new Error("Postnummer är 5 siffror långa och skrivs som XXX XX eller XXXXX")
    }
    if (value.length > 6) {
        throw new Error("Postnumret får inte vara längre än 6 tecken (inklusive mellanslag)")
    }
    if (/[^0-9]/.test(trimmedPostal)) {
        throw new Error("Får bara ange nummer och (ett) mellanslag i postnumret")
    }
}

export const checkPhoneFormat = (value: string): void => {
    const trimmedPhone = value.replace(/\s+/g,'');
    if (/[^0-9-\t]/.test(trimmedPhone)) {
        throw new Error("Telefonnumret får bara innehålla siffror och -, som 070-XXXXXX")
    }
}
